import React, { useContext, useEffect, useState, useRef } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom';
import { ConfigTableContext } from '../../helpers/AuthContext'
import { Modal, Button } from "react-bootstrap";


const ApplicationStatus = () => {
  const history = useHistory();
  const { configTableContent } = useContext(ConfigTableContext);

  useEffect(() => {
    const captchaVerified = localStorage.getItem('captcha');
    if (!captchaVerified) {
      history.push('/captcha')
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA' || event.target.isContentEditable) {
        return;
      }
      switch (event.key) {
        case 'Backspace':
          event.preventDefault();
          history.push('/')
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const [show, setShow] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const handleClose = () => setShow(false);
  const okbtnRef = useRef(null);

  const didnothaveapplicantid = () => {
    setShow(true);
    setModalMessage(["1", <><div>Use [Fill Application] button to create your profile.</div></>]);
  };

  useEffect(() => {
    if (show && okbtnRef.current) {
      okbtnRef.current.focus();
    }
  }, [show]);

  const [candidateId, setCandidateId] = useState("")

  const checkStatus = () => {
    axios.post("https://gsm2-api.aajkaa.in/applicant/checkapplicationstatus", {
      candidateId: candidateId
    }).then((response) => {
      if(response.data == "complete your profile"){
        setShow(true);
        setModalMessage(["1", <><div>Complete your application.</div><br /><div>Use the [Fill Application] button.</div></>]);
      }else if(response.data == "not found"){
        setShow(true);
        setModalMessage(["1", <><div>Use [Fill Application] button to create your profile.</div></>]);
      }else if(response.data == "in complete"){
        setShow(true);
        setModalMessage(["1", <><div>Upload two photos.</div><br /><div>Use the [Modify Application] button.</div></>]);
      }else if(response.data == "under process"){
        setShow(true);
        setModalMessage(["2", <><div>Verification pending</div><br /><div><button onClick={requsetVerification} className='btn btn-primary'>Request Verification</button></div></>]);
      }else if(response.data == "approved"){
        setShow(true);
        setModalMessage(["1", <><div>Your application is verified...</div><br /><div>Please visit <Link to="/match" className="text-primary text-decoration-none">gsm2.aajkaa.in/match</Link></div></>]);
      }
    })
  }

  const requsetVerification = () => {
    axios.post("https://gsm2-api.aajkaa.in/applicant/requestverificationrefernce", {
      candidateId: candidateId
    }).then((response) => {
      if(response.data == "success"){
        setShow(true);
        setModalMessage(["1", <><div>Request message sent to your reference person.</div><br /><div>Kindly confirm with them.</div></>]);
      }else if(response.data == "already send to reference"){
        setShow(true);
        setModalMessage(["1", <><div>Your request is already executed.</div><br /><div>Kindly conform with your reference person.</div></>]);
      }
    })
  }

  return (
    <div>

      <Modal show={show} onHide={handleClose} keyboard={false} >
        <Modal.Header>
          <Modal.Title className="text-primary">GSM says</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-primary">
          {modalMessage[1]}
        </Modal.Body>
        <Modal.Footer>
          {
            modalMessage[0] == '2' ? (
              <Button variant="secondary" onClick={handleClose} ref={okbtnRef} autoFocus>
                Ok
              </Button>
            ) : (
              <Link to="/" className="btn btn-secondary" ref={okbtnRef}>Ok</Link>
            )
          }
        </Modal.Footer>
      </Modal>


      <h1 className="text-primary text-center mt-2">
        {configTableContent.project_header}
      </h1>

      <div className="container d-flex justify-content-between align-items-center mt-2">
        <Link to='/' className="btn btn-outline-primary">
          <i className="bi bi-caret-left-fill"></i> Back
        </Link>
        <h2 className="text-primary text-center flex-grow-1 m-0">Application Status</h2>
        <div className="empty-space" style={{ width: '100px' }}></div>
      </div>

      <div className="container">
        <div className="h4 pb-2 text-primary border-bottom border-primary border-3 text-center" id="title" ></div>
        <div className="my-3 col-md-4">
          <label className="form-label">Applicant ID / Candidate ID</label>
          <input type="number" className="form-control" onChange={(e) => { setCandidateId(e.target.value) }} id="exampleInputEmail1" autoFocus autoComplete="off" />
        </div>
        <button type="button" className="btn btn-primary" onClick={checkStatus}>Check</button>
        <br />
        <div className='mt-3'>
          <Link className="text-primary text-decoration-none" to="/requestapplicantid">Forgot Applicant-ID</Link>
          <span className="mx-4 border border-2 border-primary d-none d-md-inline" style={{ height: "10px" }}></span>
          <br className="d-md-none d-block" />
          <Link to="/applicationstatus" className="text-primary text-decoration-none" onClick={didnothaveapplicantid}>Don't have Applicant-ID</Link>
        </div>
      </div>
      <footer className="fixed-bottom" style={{ color: "white", backgroundColor: '#99999b', padding: '5px', textAlign: 'center', fontSize: "13px" }}>{configTableContent.project_footer}</footer>
    </div>
  )
}

export default ApplicationStatus
