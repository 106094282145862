import { BrowserRouter as Router, Route, Switch, Link, useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import './App.css'



import Login from "./pages/Login";
import PageNotFound from "./pages/PageNotFound";
import Notallowed from "./pages/Notallowed";
import Loading from "./pages/Loading";



import Captcha from './components/applicant/Captcha'
import Dashboard from './components/applicant/Dashboard'
import FillApplication from './components/applicant/FillApplication'
import ApplicationStatus from './components/applicant/ApplicationStatus'
import PrintApplication from './components/applicant/PrintApplication'
import ModifyApplication from './components/applicant/ModifyApplication'
import RequestApplicantId from './components/applicant/RequestApplicantId'


import { AuthContext } from "./helpers/AuthContext";
// import { CaptchaContext } from "./helpers/AuthContext";
import { ConfigTableContext } from "./helpers/AuthContext";




function App() {

  const [authState, setAuthState] = useState({
    username: "",
    id: 0,
    usertype: "",
    whatsappnumber: "",
    status: false,
  });

  // const [captchaVerified, setCaptchaVerified] = useState(null);

  const [configTableContent, setConfigTableContent] = useState({
    temp: ""
  });

  useEffect(() => {
    axios.post("https://gsm2-api.aajkaa.in/applicant/getinfoofconfigtable").then((response) => {
      setConfigTableContent({
        project_header: response.data[0].project_header,
        project_footer: response.data[0].project_footer
      });
    });
  }, []);

  // const [onlineStatus, setOnlineStatus] = useState(navigator.onLine);
  // const [showOnlineNotification, setShowOnlineNotification] = useState(false);

  // useEffect(() => {
  //   const handleOnline = () => {
  //     setOnlineStatus(true);
  //     setShowOnlineNotification(true);
  //     setTimeout(() => setShowOnlineNotification(false), 2000);
  //   };

  //   const handleOffline = () => setOnlineStatus(false);

  //   window.addEventListener("online", handleOnline);
  //   window.addEventListener("offline", handleOffline);

  //   return () => {
  //     window.removeEventListener("online", handleOnline);
  //     window.removeEventListener("offline", handleOffline);
  //   };
  // }, []);

  // useEffect(() => {
  //   // axios
  //   //   .get("http://localhost:3001/auth/auth", {
  //   //     headers: {
  //   //       accessToken: localStorage.getItem("accessToken"),
  //   //     },
  //   //   })
  //   //   .then((response) => {
  //   //     if (response.data.error) {
  //   //       setAuthState({ ...authState, status: false });
  //   //     } else {
  //   //       setAuthState({
  //   //         username: response.data.username,
  //   //         id: response.data.id,
  //   //         usertype: response.data.usertype,
  //   //         whatsappnumber: response.data.whatsappnumber,
  //   //         status: true,
  //   //       });
  //   //     }
  //   //   });
  // }, []);

  let history = useHistory();

  // const logout = () => {
  //   localStorage.removeItem("accessToken");
  //   setAuthState({ username: "", id: 0, status: false });
  //   history.push('/login');
  //   window.location.reload()
  // };

  return (
    <div className="App">
      <AuthContext.Provider value={{ authState, setAuthState }}>
        {/* <CaptchaContext.Provider value={{ captchaVerified, setCaptchaVerified }}> */}
        <ConfigTableContext.Provider value={{ configTableContent, setConfigTableContent }}>

          <Router>
            <Switch>


              <Route path="/captcha" exact component={Captcha} />
              <Route path="/" exact component={Dashboard} />
              <Route path="/fillapplication" exact component={FillApplication} />
              <Route path="/applicationstatus" exact component={ApplicationStatus} />
              <Route path="/printapplication" exact component={PrintApplication} />
              <Route path="/modifyapplication" exact component={ModifyApplication} />
              <Route path="/requestapplicantid" exact component={RequestApplicantId} />


              <Route path="/login" exact component={Login} />
              <Route path="/notallowed" exact component={Notallowed} />
              <Route path="/loading" component={Loading} />
              <Route path="*" exact component={PageNotFound} />
            </Switch>
            {/* {!onlineStatus && (
                <div className="offline-strip">
                  You are currently offline.
                </div>
              )}
              {showOnlineNotification && (
                <div className="online-strip">
                  You are back online.
                </div>
              )} */}
          </Router>
        </ConfigTableContext.Provider>
        {/* </CaptchaContext.Provider> */}
      </AuthContext.Provider>
    </div>
  );
}

export default App;
