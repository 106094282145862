import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom'
import './Dashboard.css'
import image from '../photo/image.png'
import { useHistory } from 'react-router-dom';
import { ConfigTableContext } from '../../helpers/AuthContext'
import { Modal, Button } from "react-bootstrap";


const Dashboard = () => {
  const history = useHistory();
  const { configTableContent } = useContext(ConfigTableContext);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [configInfo, setConfigInfo] = useState([])
  const [visitCountDateWise, setVisitCountDateWise] = useState([])

  useEffect(() => {
    const captchaVerified = localStorage.getItem('captcha');
    if (!captchaVerified) {
      history.push('/captcha')
    }
    axios.post("https://gsm2-api.aajkaa.in/applicant/getinfoofconfigtable").then((response) => {
      setConfigInfo(response.data[0])
    });
    axios.post("https://gsm2-api.aajkaa.in/applicant/getinfoofvisitcountdatewise").then((response) => {
      setVisitCountDateWise(response.data[0])
    });
  }, []);

  return (
    <div>


      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
        <Modal.Header>
          <Modal.Title className="text-primary">GSM says</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-primary">
          <b>Development in progress…</b>
          <br />
          <b>Please wait…</b>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} autoFocus>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>


      <h1 className="text-primary text-center mt-2">
        {configTableContent.project_header}
      </h1>
      <div className="container-fluid" style={{ display: "flex", flexDirection: "row", height: "100%" }} >
        <div className="col-md-5 col-xs-12 p-4">
          <div className="row">
            <div className="col-md-12">
              <div className="row mb-2 gap-2">
                <Link className="col btn btn-primary" to="/fillapplication" style={{ whiteSpace: "nowrap", fontSize: "clamp(14px, 3vw, 24px)" }} id="button" >
                  Fill Application
                </Link>
                <Link className="col btn btn-primary" to="/applicationstatus" style={{ whiteSpace: "nowrap", fontSize: "clamp(14px, 3vw, 24px)" }} id="button" >
                  Application Status
                </Link>
                {/* <Link onClick={()=>{setShow(true)}} className="col btn btn-primary" to="/" style={{ whiteSpace: "nowrap", fontSize: "clamp(14px, 3vw, 24px)" }} id="button" >
                  Application Status
                </Link> */}
              </div>
              <div className="row mb-2 gap-2">
                <Link className="col btn btn-primary" to="/printapplication" style={{ whiteSpace: "nowrap", fontSize: "clamp(14px, 3vw,22px)" }} id="button" >
                  Print Application
                </Link>
                <Link className="col btn btn-primary" to="/modifyapplication" style={{ whiteSpace: "nowrap", fontSize: "clamp(14px, 3vw,22px)" }} id="button" >
                  Modify Application
                </Link>
              </div>
              <div className="row gap-2">
                <Link className="col btn btn-primary" to="/requestapplicantid" style={{ whiteSpace: "nowrap", fontSize: "clamp(14px, 3vw, 24px)" }} >
                  Request Applicant ID
                </Link>
              </div>
            </div>
          </div>

          <div className="row mt-5 largeOnly">
            <div className="col-md-12">
              <img src={image} alt="" className="img-fluid" />
            </div>
          </div>


        </div>
        <div className="col-md-7 col-sm-12 p-4 ">
          <div className="row mb-3">
            <div className="col-md-12 text-primary textfontsize" >
              <h3>User Instructions:</h3>
              <ul>
                <li>
                  To get married, click on <b>[Fill Application] </b> button.
                </li>
                <li>
                  To be able to fill the application successfully, you need to have
                  <ul>
                    <li>A valid working accessible Whatsapp number.</li>
                    <li>
                      Two photos of the applicant: <br />one Profile Photo<b className="text-danger">(file size
                        &lt; 250KB)</b> & one Full Photo<b className="text-danger">(file size &lt; 250KB)</b>.
                      <br /><b className="text-danger">Photos upload is compulsory.</b>
                    </li>
                    <li>
                      A valid working Whatsapp number of a <b><u>reference person</u></b>.
                    </li>
                  </ul>
                </li>
                <li>
                  Enter your Whatsapp number and get it validated thru an OTP.
                </li>
                <li>
                  On successful validation of your Whatsapp number, fill the application form.{" "}
                </li>
                <li>
                  {" "}
                  After submitting the application form
                  <br />
                  you receive Applicant-Id on your verified Whatsapp number. <br />
                  you have to upload 2 photos
                </li>
                <li>
                  After photos upload, the reference person is
                  informed & asked for confirmation.
                </li>
                <li>
                  When the reference person replies with a "yes" followed by your
                  applicant-Id,
                  <ul>
                    <li>
                      Your profile(application form) will be published(visible to
                      other candidates).
                    </li>
                    <li>
                      Your Applicant-Id becomes Candidate-Id & you receive your password.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className="row mt-5 mb-5 smallOnly">
            <div className="col-md-12">
              <img src={image} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>

      <footer className="fixed-bottom" style={{ color: "white", backgroundColor: '#99999b', padding: '5px', textAlign: 'center', fontSize: "13px" }}>{configTableContent.project_footer} ({configInfo.visit_count} {configInfo.captcha_vc} {configInfo.otp_vc}) ({visitCountDateWise.visit_count} {visitCountDateWise.captcha_vc} {visitCountDateWise.otp_vc})</footer>
    </div>
  );
}

export default Dashboard;
