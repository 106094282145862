import React, { useEffect, useState, useContext, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { ConfigTableContext } from '../../helpers/AuthContext';
import { Modal, Button } from "react-bootstrap";
import UploadPhoto from './UploadPhoto';
import { TextField, InputAdornment } from '@mui/material';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs'; // Make sure you have installed dayjs

import MenuItem from '@mui/material/MenuItem';

import { Input as BaseInput } from '@mui/base/Input';
import { styled } from '@mui/system';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FillApplication = () => {
  const history = useHistory();
  const { configTableContent } = useContext(ConfigTableContext);

  const [modalMessage, setModalMessage] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  // Refs for the input fields
  const whatsappNumberRef = useRef(null);
  const otpRef = useRef(null);

  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [generatedOtp, setGeneratedOtp] = useState('');

  const [showOtp, setShowOtp] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [uploadPhoto, showUploadPhoto] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA' || event.target.isContentEditable) {
        return;
      }
      switch (event.key) {
        case 'Backspace':
          event.preventDefault();
          history.push('/');
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const captchaVerified = localStorage.getItem('captcha');
    if (!captchaVerified) {
      history.push('/captcha');
    }
  }, []);

  const getOtpClicked = () => {
    if (whatsappNumber.length === 10) {
      setBtnLoading(true);
      axios.post("https://gsm2-api.aajkaa.in/applicant/checkwhatsappnumber", {
        whatsappNumber: whatsappNumber
      }).then((response) => {
        if (response.data === false) {
          setBtnLoading(false);
          setShow(true);
          setModalMessage(["1", "This WhatsApp number is already registered, Login using your candidate-ID"]);
        } else if (response.data == 'uploadimg') {
          setBtnLoading(false);
          setShow(true);
          setModalMessage(["1", "Photos upload pending"]);
        } else if (response.data == 'photos uploaded but status pending') {
          whatsappNumberRef.current.focus()
          setBtnLoading(false);
          setShow(true);
          setModalMessage(["1", "Approval pending"]);
        } else {
          otpRef.current.focus();
          setBtnLoading(false);
          setGeneratedOtp(response.data);
          setShow(true);
          setModalMessage(["1", "OTP sent to your WhatsApp number"]);
        }
      });
    } else {
      setShow(true);
      setModalMessage(["3", "Enter Valid Number"]);
      whatsappNumberRef.current.focus(); // Focus back on the WhatsApp number input field
    }
  };


  const [verifyOtpBtnLoading, setVerifyOtpBtnLoading] = useState(false);

  const verifyOtpClicked = () => {
    if (otp === generatedOtp && otp.length === 4) {
      setVerifyOtpBtnLoading(true);
      axios.post("https://gsm2-api.aajkaa.in/applicant/retrivedatafromcandidatetable", {
        whatsappNumber: whatsappNumber,
      }).then((response) => {
        setCandidateID(response.data[0]["candidate_id"] == null ? "" : response.data[0]["candidate_id"]);
        setCandidateFirstName(response.data[0]["candidate_firstname"] == null ? "" : response.data[0]["candidate_firstname"]);
        setCandidateMiddleName(response.data[0]["candidate_middlename"] == null ? "" : response.data[0]["candidate_middlename"]);
        setCandidateLastName(response.data[0]["candidate_lastname"] == null ? "" : response.data[0]["candidate_lastname"]);
        setCurrentAddress(response.data[0]["candidate_current_address"] == null ? "" : response.data[0]["candidate_current_address"]);
        setDob(response.data[0]["date_of_birth"] == null ? "" : response.data[0]["date_of_birth"]);
        setMartitalStatus(response.data[0]["marital_status"] == null ? "" : response.data[0]["marital_status"]);
        setReligion(response.data[0]["religion"] == null ? "" : response.data[0]["religion"]);
        setGender(response.data[0]["gender"] == null ? "" : response.data[0]["gender"]);
        setHeight(response.data[0]["height_cm"] == null ? "" : response.data[0]["height_cm"]);
        setWeight(response.data[0]["weight"] == null ? "" : response.data[0]["weight"]);
        setAnyDisability(response.data[0]["any_disability"] == null ? "" : response.data[0]["any_disability"]);
        setMotherTongue(response.data[0]["mother_tongue"] == null ? "" : response.data[0]["mother_tongue"]);
        setKnownLanguages(response.data[0]["languages_known"] == null ? "" : response.data[0]["languages_known"]);
        setNationality(response.data[0]["nationality"] == null ? "" : response.data[0]["nationality"]);
        setWhatsappNumber(response.data[0]["whatsapp_number"] == null ? "" : response.data[0]["whatsapp_number"]);
        setEmail(response.data[0]["email_id"] == null ? "" : response.data[0]["email_id"]);
        setQualification(response.data[0]["qualification"] == null ? "" : response.data[0]["qualification"]);
        setHighestDegree(response.data[0]["degree"] == null ? "" : response.data[0]["degree"]);
        setOccupation(response.data[0]["candidate_occupation"] == null ? "" : response.data[0]["candidate_occupation"]);
        setNatureOfWork(response.data[0]["nature_of_work"] == null ? "" : response.data[0]["nature_of_work"]);
        setCurrency(response.data[0]["currency"] == null ? "" : response.data[0]["currency"]);
        setCandidateAnnualIncome(response.data[0]["candidate_annual_income"] == null ? "" : response.data[0]["candidate_annual_income"]);
        setFamilyPermanentAddress(response.data[0]["family_permanent_address"] == null ? "" : response.data[0]["family_permanent_address"]);
        setFamilyAnnualIncome(response.data[0]["family_annual_income"] == null ? "" : response.data[0]["family_annual_income"]);
        setGurudwaraNormallyVisited(response.data[0]["gurudwara_normally_visited"] == null ? "" : response.data[0]["gurudwara_normally_visited"]);
        setGurudwaraContactName(response.data[0]["gurudwara_contact_person"] == null ? "" : response.data[0]["gurudwara_contact_person"]);
        setGurudwaraContactNumber(response.data[0]["gurudwara_phone_number"] == null ? "" : response.data[0]["gurudwara_phone_number"]);
        setAnyOtherInfo(response.data[0]["additional_information"] == null ? "" : response.data[0]["additional_information"]);
        setReferencePersonName(response.data[0]["reference_name"] == null ? "" : response.data[0]["reference_name"]);
        setReferencePersonWAN(response.data[0]["reference_contact"] == null ? "" : response.data[0]["reference_contact"]);

        axios.post("https://gsm2-api.aajkaa.in/applicant/familydata1", {
          CandidateID: response.data.map((val, key) => val.candidate_id),
        }).then((response) => {
          setFatherRowId(response.data[0]['id'] == null ? "" : response.data[0]['id']);
          setFatherName(response.data[0]['relative_name'] == null ? "" : response.data[0]['relative_name']);
          setFatherAge(response.data[0]['relative_age'] == null ? "" : response.data[0]['relative_age']);
          setFatherQualification(response.data[0]['relative_qualification'] == null ? "" : response.data[0]['relative_qualification']);
          setFatherOccupation(response.data[0]['relative_occupation'] == null ? "" : response.data[0]['relative_occupation']);

          setMotherRowId(response.data[1]['id'] == null ? "" : response.data[1]['id']);
          setMotherName(response.data[1]['relative_name'] == null ? "" : response.data[1]['relative_name']);
          setMotherAge(response.data[1]['relative_age'] == null ? "" : response.data[1]['relative_age']);
          setMotherQualification(response.data[1]['relative_qualification'] == null ? "" : response.data[1]['relative_qualification']);
          setMotherOccupation(response.data[1]['relative_occupation'] == null ? "" : response.data[1]['relative_occupation']);

          setRelative3RowId(response.data[2]['id'] == null ? "" : response.data[2]['id']);
          setRelative3Relation(response.data[2]['relation_ship'] == null ? "" : response.data[2]['relation_ship']);
          setRelative3Name(response.data[2]['relative_name'] == null ? "" : response.data[2]['relative_name']);
          setRelative3Age(response.data[2]['relative_age'] == null ? "" : response.data[2]['relative_age']);
          setRelative3Qualification(response.data[2]['relative_qualification'] == null ? "" : response.data[2]['relative_qualification']);
          setRelative3Occupation(response.data[2]['relative_occupation'] == null ? "" : response.data[2]['relative_occupation']);

          setRelative4RowId(response.data[3]['id'] == null ? "" : response.data[3]['id']);
          setRelative4Relation(response.data[3]['relation_ship'] == null ? "" : response.data[3]['relation_ship']);
          setRelative4Name(response.data[3]['relative_name'] == null ? "" : response.data[3]['relative_name']);
          setRelative4Age(response.data[3]['relative_age'] == null ? "" : response.data[3]['relative_age']);
          setRelative4Qualification(response.data[3]['relative_qualification'] == null ? "" : response.data[3]['relative_qualification']);
          setRelative4Occupation(response.data[3]['relative_occupation'] == null ? "" : response.data[3]['relative_occupation']);

          setRelative5RowId(response.data[4]['id'] == null ? "" : response.data[4]['id']);
          setRelative5Relation(response.data[4]['relation_ship'] == null ? "" : response.data[4]['relation_ship']);
          setRelative5Name(response.data[4]['relative_name'] == null ? "" : response.data[4]['relative_name']);
          setRelative5Age(response.data[4]['relative_age'] == null ? "" : response.data[4]['relative_age']);
          setRelative5Qualification(response.data[4]['relative_qualification'] == null ? "" : response.data[4]['relative_qualification']);
          setRelative5Occupation(response.data[4]['relative_occupation'] == null ? "" : response.data[4]['relative_occupation']);

          setRelative6RowId(response.data[5]['id'] == null ? "" : response.data[5]['id']);
          setRelative6Relation(response.data[5]['relation_ship'] == null ? "" : response.data[5]['relation_ship']);
          setRelative6Name(response.data[5]['relative_name'] == null ? "" : response.data[5]['relative_name']);
          setRelative6Age(response.data[5]['relative_age'] == null ? "" : response.data[5]['relative_age']);
          setRelative6Qualification(response.data[5]['relative_qualification'] == null ? "" : response.data[5]['relative_qualification']);
          setRelative6Occupation(response.data[5]['relative_occupation'] == null ? "" : response.data[5]['relative_occupation']);

          setRelative7RowId(response.data[6]['id'] == null ? "" : response.data[6]['id']);
          setRelative7Relation(response.data[6]['relation_ship'] == null ? "" : response.data[6]['relation_ship']);
          setRelative7Name(response.data[6]['relative_name'] == null ? "" : response.data[6]['relative_name']);
          setRelative7Age(response.data[6]['relative_age'] == null ? "" : response.data[6]['relative_age']);
          setRelative7Qualification(response.data[6]['relative_qualification'] == null ? "" : response.data[6]['relative_qualification']);
          setRelative7Occupation(response.data[6]['relative_occupation'] == null ? "" : response.data[6]['relative_occupation']);
          setVerifyOtpBtnLoading(false);
          setShowOtp(false);
          setShowForm(true);
        });
      });
      axios.post("https://gsm2-api.aajkaa.in/applicant/increasecountforveriftotp").then((response) => {
        console.log(response.data)
      })
    } else {
      setShow(true);
      setModalMessage(["2", "OTP incorrect. Try again..."]);
      otpRef.current.focus(); // Focus back on the OTP input field
    }
  };

  const [dbMaritalStatusOption, setDbMaritalStatusOption] = useState([])
  const [dbReligionOption, setDbReligionOption] = useState([])
  const [dbGenderOption, setDbGenderOption] = useState([])
  const [dbQualificationOption, setDbQualificationOption] = useState([])
  const [dbOccupationOption, setDbOccupationOption] = useState([])
  const [dbCurrencyOption, setDbCurrencyOption] = useState([])
  const [dbNature_of_workOption, setDbNature_of_workOption] = useState([])
  const [dbRelationshipOption, setDbRelationshipOption] = useState([])

  useEffect(() => {
    axios.post("https://gsm2-api.aajkaa.in/applicant/getddllists").then((response) => {
      setDbMaritalStatusOption(response.data[0].marital_status.split(','))
      setDbReligionOption(response.data[0].religion.split(','))
      setDbGenderOption(response.data[0].gender.split(','))
      setDbQualificationOption(response.data[0].education.split(','))
      setDbOccupationOption(response.data[0].occupation.split(','))
      setDbCurrencyOption(response.data[0].currency.split(','))
      setDbNature_of_workOption(response.data[0].nature_of_work.split(','))
      setDbRelationshipOption(response.data[0].relationship.split(','))
    });
  }, [])

  const [cmValue, setCmValue] = useState(0);
  const [feet, setFeetValue] = useState(0);
  const cmToFeet = (cm) => {
    const totalInches = Math.round(cm / 2.54);
    const feet = Math.floor(totalInches / 12);
    const inches = totalInches % 12;
    return `${feet} feet ${inches} inches`;
  };

  const emailInputRef = useRef(null);
  const setCursorPosition = () => {
    if (emailInputRef.current) {
      emailInputRef.current.type = "text";
      emailInputRef.current.setSelectionRange(0, 0);
      emailInputRef.current.type = "email";
    }
  };

  const [CandidateID, setCandidateID] = useState("")

  const [candidateFirstName, setCandidateFirstName] = useState("")
  const [candidateMiddleName, setCandidateMiddleName] = useState("")
  const [candidateLastName, setCandidateLastName] = useState("")
  const [currentAddress, setCurrentAddress] = useState("")
  const [dob, setDob] = useState("");
  const [martitalStatus, setMartitalStatus] = useState("")
  const [religion, setReligion] = useState("")
  const [gender, setGender] = useState("")
  const [height, setHeight] = useState("")
  const [weight, setWeight] = useState("")
  const [anyDisability, setAnyDisability] = useState("")
  const [motherTongue, setMotherTongue] = useState("")
  const [knownLanguages, setKnownLanguages] = useState("")
  const [nationality, setNationality] = useState("")
  const [email, setEmail] = useState("")
  const [qualification, setQualification] = useState("")
  const [highestDegree, setHighestDegree] = useState("")
  const [occupation, setOccupation] = useState("")
  const [natureOfWork, setNatureOfWork] = useState("")
  const [currency, setCurrency] = useState("")
  const [candidateAnnualIncome, setCandidateAnnualIncome] = useState("")
  const [familyPermanentAddress, setFamilyPermanentAddress] = useState("")
  const [familyAnnualIncome, setFamilyAnnualIncome] = useState("")
  const [gurudwaraNormallyVisited, setGurudwaraNormallyVisited] = useState("")
  const [gurudwaraContactName, setGurudwaraContactName] = useState("")
  const [gurudwaraContactNumber, setGurudwaraContactNumber] = useState("")
  const [anyOtherInfo, setAnyOtherInfo] = useState("")
  const [referencePersonName, setReferencePersonName] = useState("")
  const [referencePersonWAN, setReferencePersonWAN] = useState("")


  const [fatherRowId, setFatherRowId] = useState("")
  const [fatherName, setFatherName] = useState("")
  const [fatherAge, setFatherAge] = useState("")
  const [fatherQualification, setFatherQualification] = useState("")
  const [fatherOccupation, setFatherOccupation] = useState("")

  const [motherRowId, setMotherRowId] = useState("")
  const [motherName, setMotherName] = useState("")
  const [motherAge, setMotherAge] = useState("")
  const [motherQualification, setMotherQualification] = useState("")
  const [motherOccupation, setMotherOccupation] = useState("")

  const [relative3RowId, setRelative3RowId] = useState("")
  const [relative3Relation, setRelative3Relation] = useState("")
  const [relative3Name, setRelative3Name] = useState("")
  const [relative3Age, setRelative3Age] = useState("")
  const [relative3Qualification, setRelative3Qualification] = useState("")
  const [relative3Occupation, setRelative3Occupation] = useState("")

  const [relative4RowId, setRelative4RowId] = useState("")
  const [relative4Relation, setRelative4Relation] = useState("")
  const [relative4Name, setRelative4Name] = useState("")
  const [relative4Age, setRelative4Age] = useState("")
  const [relative4Qualification, setRelative4Qualification] = useState("")
  const [relative4Occupation, setRelative4Occupation] = useState("")

  const [relative5RowId, setRelative5RowId] = useState("")
  const [relative5Relation, setRelative5Relation] = useState("")
  const [relative5Name, setRelative5Name] = useState("")
  const [relative5Age, setRelative5Age] = useState("")
  const [relative5Qualification, setRelative5Qualification] = useState("")
  const [relative5Occupation, setRelative5Occupation] = useState("")

  const [relative6RowId, setRelative6RowId] = useState("")
  const [relative6Relation, setRelative6Relation] = useState("")
  const [relative6Name, setRelative6Name] = useState("")
  const [relative6Age, setRelative6Age] = useState("")
  const [relative6Qualification, setRelative6Qualification] = useState("")
  const [relative6Occupation, setRelative6Occupation] = useState("")

  const [relative7RowId, setRelative7RowId] = useState("")
  const [relative7Relation, setRelative7Relation] = useState("")
  const [relative7Name, setRelative7Name] = useState("")
  const [relative7Age, setRelative7Age] = useState("")
  const [relative7Qualification, setRelative7Qualification] = useState("")
  const [relative7Occupation, setRelative7Occupation] = useState("")


  const handleBlur = async (inputName, value) => {
    try {
      console.log(inputName, value)
      await axios.post('https://gsm2-api.aajkaa.in/applicant/updatecandidate', {
        [inputName]: value,
        candidateid: CandidateID,
      });
    } catch (error) {
      console.error(`Error saving ${inputName} to the server`, error);
    }
  };

  const familyhandleBlur = async (rowid, inputName, value) => {
    try {
      await axios.post('https://gsm2-api.aajkaa.in/applicant/updatefamilydata', {
        [inputName]: value,
        candidateid: CandidateID,
        rowid: rowid,
      });
    } catch (error) {
      console.error(`Error saving ${inputName} to the server`, error);
    }
  };

  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);

  const saveApplicant = (e) => {
    e.preventDefault()
    setSubmitBtnLoading(true)
    axios.post("https://gsm2-api.aajkaa.in/applicant/uploadtomaincandidatetable", {
      CandidateID: CandidateID,
      whatsappNumber: whatsappNumber,
      candidateFirstName: candidateFirstName,
      candidateMiddleName: candidateMiddleName,
      candidateLastName: candidateLastName,
      currentAddress: currentAddress,
      dob: dob,
      martitalStatus: martitalStatus,
      religion: religion,
      gender: gender,
      height: height,
      weight: weight,
      anyDisability: anyDisability,
      motherTongue: motherTongue,
      knownLanguages: knownLanguages,
      nationality: nationality,
      email: email,
      qualification: qualification,
      highestDegree: highestDegree,
      occupation: occupation,
      natureOfWork: natureOfWork,
      currency: currency,
      candidateAnnualIncome: candidateAnnualIncome,
      familyPermanentAddress: familyPermanentAddress,
      familyAnnualIncome: familyAnnualIncome,
      gurudwaraNormallyVisited: gurudwaraNormallyVisited,
      gurudwaraContactName: gurudwaraContactName,
      gurudwaraContactNumber: gurudwaraContactNumber,
      anyOtherInfo: anyOtherInfo,
      referencePersonName: referencePersonName,
      referencePersonWAN: referencePersonWAN,
      fatherRowId: fatherRowId,
      fatherName: fatherName,
      fatherAge: fatherAge,
      fatherQualification: fatherQualification,
      fatherOccupation: fatherOccupation,
      motherRowId: motherRowId,
      motherName: motherName,
      motherAge: motherAge,
      motherQualification: motherQualification,
      motherOccupation: motherOccupation,
      relative3RowId: relative3RowId,
      relative3Relation: relative3Relation,
      relative3Name: relative3Name,
      relative3Age: relative3Age,
      relative3Qualification: relative3Qualification,
      relative3Occupation: relative3Occupation,
      relative4RowId: relative4RowId,
      relative4Relation: relative4Relation,
      relative4Name: relative4Name,
      relative4Age: relative4Age,
      relative4Qualification: relative4Qualification,
      relative4Occupation: relative4Occupation,
      relative5RowId: relative5RowId,
      relative5Relation: relative5Relation,
      relative5Name: relative5Name,
      relative5Age: relative5Age,
      relative5Qualification: relative5Qualification,
      relative5Occupation: relative5Occupation,
      relative6RowId: relative6RowId,
      relative6Relation: relative6Relation,
      relative6Name: relative6Name,
      relative6Age: relative6Age,
      relative6Qualification: relative6Qualification,
      relative6Occupation: relative6Occupation,
      relative7RowId: relative7RowId,
      relative7Relation: relative7Relation,
      relative7Name: relative7Name,
      relative7Age: relative7Age,
      relative7Qualification: relative7Qualification,
      relative7Occupation: relative7Occupation,
    }).then((response) => {
      axios.post("https://gsm2-api.aajkaa.in/applicant/sendcandidateidonw", {
        whatsappNumber: whatsappNumber,
        CandidateID: CandidateID,
      }).then((response) => {
        setSubmitBtnLoading(false)
        showUploadPhoto(true)
        setShowForm(false)
        toast.success(<>Form submitted successfully.<br /> Applicant Id sent to your Whatsapp number.<br /> Login-password will be sent when the profile is approved.</>, {
          position: "bottom-right",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
    })
  }



  const handleDateChange = (newValue) => {
    if (newValue && newValue.isValid()) {
      setDob(newValue); // Update state with new date
      console.log(" on change ", newValue.format('YYYY-MM-DD')); // Log formatted date
      // You can send `newValue.format('YYYY-MM-DD')` to the backend if needed
    } else {
      setDob(null); // Handle invalid date case
    }
  };

  const handleBlur11 = (name, value) => {
    console.log(`${name} blurred with value: ${value}`);
    // Additional validation or processing logic can be added here
  };

  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ width: "33%" }}
      />
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
        <Modal.Header>
          <Modal.Title className="text-primary">GSM says</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-primary">{modalMessage[1]}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} autoFocus>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <h1 className="text-primary text-center mt-2">
        {configTableContent.project_header}
      </h1>
      <div className="container d-flex justify-content-between align-items-center mt-2">
        <Link to='/' className="btn btn-outline-primary">
          <i className="bi bi-caret-left-fill"></i> Back
        </Link>
        <h2 className="text-primary text-center flex-grow-1 m-0">Fill Application Form</h2>
        <div className="empty-space" style={{ width: '100px' }}></div>
      </div>

      <div className='container'>
        {showOtp && (
          <div className="row g-3 mt-1" id="otpotp">
            <div className="h4 pb-2 mb-4 text-primary border-bottom border-primary border-3 d-flex flex-wrap justify-content-between align-items-center">
              <span>
                Please provide <u>your Whatsapp phone number</u> for further communication.
              </span>
            </div>

            <div className="col-md-3">
              <TextField
                id="outlined-basic"
                label="Enter WhatsApp number"
                variant="outlined"
                type="number"
                onChange={(e) => { setWhatsappNumber(e.target.value); }}
                autoComplete="off"
                autoFocus
                inputRef={whatsappNumberRef} // Add ref here
                size="small"
                style={{ width: "100%" }}
              />
            </div>
            <div className="col-md-9">
              {btnLoading ? (
                <button className="btn btn-primary" disabled>Sending OTP...</button>
              ) : (
                <Link to="/fillapplication" className="btn btn-primary" onClick={() => { getOtpClicked(); }} >Get OTP</Link>
              )}
            </div>
            <div className="col-md-3">
              <TextField
                id="outlined-basic"
                label="Enter OTP"
                variant="outlined"
                type="number"
                size="small"
                style={{ width: "100%" }}
                onChange={(e) => { setOtp(e.target.value); }}
                inputRef={otpRef} // Add ref here
              />
            </div>
            <div className="col-md-3 mb-4">
              {verifyOtpBtnLoading ? (
                <button className="btn btn-primary" disabled>Fetching Data...</button>
              ) : (
                // <Link to="/fillapplication" className="btn btn-primary" onClick={() => { getOtpClicked(); }} >Get OTP</Link>
                <Link to="/fillapplication" className="btn btn-primary" onClick={() => { verifyOtpClicked(); }}>Verify OTP</Link>
              )}
            </div>
          </div>
        )}
        {showForm && (
          <div className='mt-2'>
            <form className="row g-3" onSubmit={(e) => { saveApplicant(e) }}>
              <div className="h4 text-primary border-bottom border-primary border-3"></div>

              <div className="col-md-4" style={{ marginTop: "-5px" }}>
                <TextField
                  autoFocus
                  required
                  className='mt-4'
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  size="small"
                  label="Applicant's First Name"
                  style={{ width: "100%" }}
                  onChange={(e) => { setCandidateFirstName(e.target.value) }}
                  value={candidateFirstName}
                  onBlur={() => handleBlur('candidate_firstname', candidateFirstName)}
                />
              </div>
              <div className="col-md-4" style={{ marginTop: "-5px" }}>
                <TextField
                  required
                  className='mt-4'
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  size="small"
                  label="Applicant's Middle Name"
                  style={{ width: "100%" }}
                  onChange={(e) => { setCandidateMiddleName(e.target.value) }}
                  value={candidateMiddleName}
                  onBlur={() => handleBlur('candidate_middlename', candidateMiddleName)}
                />
              </div>
              <div className="col-md-4" style={{ marginTop: "-5px" }}>
                <TextField
                  required
                  className='mt-4'
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  size="small"
                  label="Applicant's Last Name"
                  style={{ width: "100%" }}
                  onChange={(e) => { setCandidateLastName(e.target.value) }}
                  value={candidateLastName}
                  onBlur={() => handleBlur('candidate_lastname', candidateLastName)}
                />
              </div>

              <div className="col-md-12">
                <TextField
                  required
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  size="small"
                  label="Applicant's Current Address"
                  style={{ width: "100%" }}
                  onChange={(e) => { setCurrentAddress(e.target.value) }}
                  value={currentAddress}
                  onBlur={() => handleBlur('candidate_current_address', currentAddress)}
                />
              </div>

              <div className="col-md-3 d-flex">
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker label="Basic date picker" 
                      value={dob}
                      />
                  </DemoContainer>
                </LocalizationProvider> */}
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker
                      required
                      label="Date of Birth"
                      inputFormat="DD/MM/YYYY" // Use `inputFormat` instead of `format`
                      size="small"
                      value={dob}
                      onChange={handleDateChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onBlur={(event) => {
                            params.inputProps?.onBlur?.(event); // Call default onBlur handler if it exists
                            handleBlur11('date_of_birth', dob ? dob.format('YYYY-MM-DD') : ''); // Custom onBlur handling
                          }}
                          size="small"
                          required
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider> */}
                {/* // onChange={(e) => { setDateOfBirth(e.target.value) }} // Update state when the value changes
                // value={dateOfBirth} // Bind value to the date state
                onBlur={() => handleBlur('date_of_birth', dateOfBirth)} // Optional: handle onBlur event */}
                {/* /> */}
                <label htmlFor="validationCustom02" className="form-label fs-6 col-5">
                  Date of Birth <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="date"
                  required
                  onChange={(e) => { setDob(e.target.value) }}
                  value={dob}
                  onBlur={() => handleBlur('date_of_birth', dob)}
                />

                {/* <input
                  className="form-control"
                  type="date"
                  value={DateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                  onBlur={() => handleBlur('dob', DateOfBirth)}
                  required
                /> */}
              </div>
              <div className="col-md-3">
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Marital Status"
                  size="small"
                  required
                  style={{ width: "100%" }}
                  onBlur={() => handleBlur('marital_status', martitalStatus)}
                  value={martitalStatus}
                  onChange={(e) => { setMartitalStatus(e.target.value) }}
                >
                  {dbMaritalStatusOption.map((val, key) => (
                    <MenuItem key={val} value={val}>
                      {val}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="col-md-3">
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Religion"
                  size="small"
                  required
                  style={{ width: "100%" }}
                  onBlur={() => handleBlur('religion', religion)}
                  value={religion}
                  onChange={(e) => { setReligion(e.target.value) }}
                >
                  {dbReligionOption.map((val, key) => (
                    <MenuItem key={val} value={val}>
                      {val}
                    </MenuItem>
                  ))}
                </TextField>
                {/* <label htmlFor="validationCustom04" className="form-label fs-5">
                  Religion <span className="text-danger">*</span>
                </label>
                <select required className="form-select" onBlur={() => handleBlur('religion', religion)} value={religion} onChange={(e) => { setReligion(e.target.value) }}>
                  <option disabled value="" >
                    Select Religion
                  </option>
                  {dbReligionOption.map((val, key) =>
                    <option key={key} value={val} >{val}</option>
                  )}
                </select> */}
              </div>
              <div className="col-md-3">
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Gender"
                  size="small"
                  required
                  style={{ width: "100%" }}
                  onBlur={() => handleBlur('gender', gender)}
                  value={gender}
                  onChange={(e) => { setGender(e.target.value) }}
                >
                  {dbGenderOption.map((val, key) => (
                    <MenuItem key={val} value={val}>
                      {val}
                    </MenuItem>
                  ))}
                </TextField>
                {/* <label htmlFor="validationCustom04" className="form-label fs-5">
                  Gender <span className="text-danger">*</span>
                </label>
                <select required className="form-select" onBlur={() => handleBlur('gender', gender)} value={gender} onChange={(e) => { setGender(e.target.value) }}>
                  <option disabled value="">
                    Select Gender
                  </option>
                  {dbGenderOption.map((val, key) => (
                    <option key={key} value={val} >{val}</option>
                  ))}
                </select> */}
              </div>

              <div className="col-md-4">
                <TextField
                  label={`Height: ${feet}`}
                  id="outlined-start-adornment"
                  sx={{ width: '100%' }}
                  size="small"
                  required
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">cm</InputAdornment>,
                  }}
                  onChange={(event) => {
                    setHeight(event.target.value);
                    const cm = event.target.value;
                    const feet = cmToFeet(cm);
                    setCmValue(cm);
                    setFeetValue(feet);
                  }}
                  onBlur={() => handleBlur('height_cm', height)}
                  value={height}
                />
                {/* <label
                  htmlFor="validationCustomUsername"
                  className="form-label fs-5"
                >
                  Height : {feet} <span className="text-danger">*</span>
                </label>
                <div className="input-group has-validation">
                  <span className="input-group-text" id="inputGroupPrepend">
                    cm
                  </span>
                  <input
                    type="number"
                    className="form-control"
                    aria-describedby="inputGroupPrepend"
                    value={height}
                    onChange={(event) => {
                      setHeight(event.target.value);
                      const cm = event.target.value;
                      const feet = cmToFeet(cm);
                      setCmValue(cm);
                      setFeetValue(feet);
                    }}
                    onBlur={() => handleBlur('height_cm', height)}
                    required
                  /> */}
                {/* <div className="invalid-feedback">Please choose a username.</div> */}
                {/* </div> */}
              </div>

              <div className="col-md-4">
                <TextField
                  type="number"
                  label="Weight"
                  id="outlined-start-adornment"
                  sx={{ width: '100%' }}
                  size="small"
                  required
                  InputProps={{
                    startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                  }}
                  onChange={(e) => { setWeight(e.target.value) }}
                  value={weight}
                  onBlur={() => handleBlur('weight', weight)}
                />
                {/* <label
                  htmlFor="validationCustomUsername"
                  className="form-label fs-5"
                >
                  Weight <span className="text-danger">*</span>
                </label>
                <div className="input-group has-validation">
                  <span className="input-group-text" id="inputGroupPrepend">
                    kg
                  </span>
                  <input
                    type="number"
                    className="form-control"
                    aria-describedby="inputGroupPrepend"
                    required
                    onChange={(e) => { setWeight(e.target.value) }}
                    value={weight}
                    onBlur={() => handleBlur('weight', weight)}
                  /> */}
                {/* <div className="invalid-feedback">Please choose a username.</div> */}
                {/* </div> */}
              </div>
              <div className="col-md-4">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  size="small"
                  label="Any Disability?"
                  style={{ width: "100%" }}
                  onChange={(e) => { setAnyDisability(e.target.value) }}
                  value={anyDisability}
                  onBlur={() => handleBlur('any_disability', anyDisability)}
                />
                {/* <label htmlFor="validationCustom01" className="form-label fs-5">
                  Any Disability?
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="mental and/or physical"
                  onChange={(e) => { setAnyDisability(e.target.value) }}
                  value={anyDisability}
                  onBlur={() => handleBlur('any_disability', anyDisability)}
                /> */}
              </div>

              <div className="col-md-4">
                <TextField
                  required
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  size="small"
                  label="Mother Tongue"
                  style={{ width: "100%" }}
                  onChange={(e) => { setMotherTongue(e.target.value) }}
                  value={motherTongue}
                  onBlur={() => handleBlur('mother_tongue', motherTongue)}
                />

                {/* <label htmlFor="validationCustom02" className="form-label fs-5">
                  Mother Tongue <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  required
                  onChange={(e) => { setMotherTongue(e.target.value) }}
                  value={motherTongue}
                  onBlur={() => handleBlur('mother_tongue', motherTongue)}
                /> */}
              </div>
              <div className="col-md-4">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  size="small"
                  label="Languages Known"
                  style={{ width: "100%" }}
                  onChange={(e) => { setKnownLanguages(e.target.value) }}
                  value={knownLanguages}
                  onBlur={() => handleBlur('languages_known', knownLanguages)}
                />

                {/* <label htmlFor="validationCustom02" className="form-label fs-5">
                  Languages Known
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => { setKnownLanguages(e.target.value) }}
                  value={knownLanguages}
                  onBlur={() => handleBlur('languages_known', knownLanguages)}
                /> */}
              </div>
              <div className="col-md-4">
                <TextField
                  required
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  size="small"
                  label="Nationality"
                  style={{ width: "100%" }}
                  onChange={(e) => { setNationality(e.target.value) }}
                  value={nationality}
                  onBlur={() => handleBlur('nationality', nationality)}
                />

                {/* <label htmlFor="validationCustom02" className="form-label fs-5">
                  Nationality <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  required
                  onChange={(e) => { setNationality(e.target.value) }}
                  value={nationality}
                  onBlur={() => handleBlur('nationality', nationality)}
                /> */}
              </div>

              <div className="col-md-3">
                <TextField
                  disabled
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  label="Verified Phone Number"
                  style={{ width: "100%", background: "#e9ecef" }}
                  value={whatsappNumber}
                />
                {/* <label htmlFor="validationCustom01" className="form-label fs-5">
                  Verified Phone Number
                </label>
                <input
                  className="form-control"
                  type="number"
                  disabled
                  value={whatsappNumber}
                /> */}
              </div>
              <div className="col-md-3">
                <TextField
                  required
                  id="outlined-basic"
                  variant="outlined"
                  type="email"
                  label="Email-ID"
                  size="small"
                  style={{ width: "100%" }}
                  value={email}
                  inputRef={emailInputRef}
                  onFocus={setCursorPosition}
                  onBlur={() => handleBlur('email_id', email)}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
                {/* <TextField
                  type="email"
                  label="Email-ID"
                  id="outlined-start-adornment"
                  sx={{ width: '100%' }}
                  size="small"
                  required
                  InputProps={{
                    startAdornment: <InputAdornment position="start">@</InputAdornment>,
                  }}
                  value={email}
                  inputRef={emailInputRef}
                  onFocus={setCursorPosition}
                  onBlur={() => handleBlur('email_id', email)}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                /> */}
                {/* <label htmlFor="validationCustom02" className="form-label fs-5">
                  Email-ID
                </label>
                <div className="input-group has-validation">
                  <span className="input-group-text" id="inputGroupPrepend">
                    @
                  </span>
                  <input
                    id="emailInput"
                    className="form-control"
                    type="email"
                    aria-describedby="inputGroupPrepend"
                    value={email}
                    ref={emailInputRef}
                    onFocus={setCursorPosition}
                    onBlur={() => handleBlur('email_id', email)}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                  <div className="invalid-feedback">Please choose a username.</div>
                </div> */}
              </div>
              <div className="col-md-3">
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Qualification"
                  size="small"
                  required
                  style={{ width: "100%" }}
                  onBlur={() => handleBlur('qualification', qualification)}
                  value={qualification}
                  onChange={(e) => { setQualification(e.target.value) }}
                >
                  {dbQualificationOption.map((val, key) => (
                    <MenuItem key={val} value={val}>
                      {val}
                    </MenuItem>
                  ))}
                </TextField>
                {/* <label htmlFor="validationCustom04" className="form-label fs-5">
                  Qualification <span className="text-danger">*</span>
                </label>
                <select required className="form-select" onBlur={() => handleBlur('qualification', qualification)} value={qualification} onChange={(e) => { setQualification(e.target.value) }}>
                  <option disabled value="">
                    Select Qualification
                  </option>
                  {dbQualificationOption.map((val, key) => (
                    <option value={val} key={key}>{val}</option>
                  ))}
                </select> */}
              </div>
              <div className="col-md-3">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  size="small"
                  label="Highest Degree"
                  style={{ width: "100%" }}
                  onChange={(e) => { setHighestDegree(e.target.value) }}
                  value={highestDegree}
                  onBlur={() => handleBlur('degree', highestDegree)}
                />
                {/* <label htmlFor="validationCustom02" className="form-label fs-5">
                  Highest Degree
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => { setHighestDegree(e.target.value) }}
                  value={highestDegree}
                  onBlur={() => handleBlur('degree', highestDegree)}
                /> */}
              </div>

              <div className="col-md-3">
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Applicant's Occupation"
                  size="small"
                  required
                  style={{ width: "100%" }}
                  onBlur={() => handleBlur('candidate_occupation', occupation)}
                  value={occupation}
                  onChange={(e) => { setOccupation(e.target.value) }}
                >
                  {dbOccupationOption.map((val, key) => (
                    <MenuItem key={val} value={val}>
                      {val}
                    </MenuItem>
                  ))}
                </TextField>
                {/* <label htmlFor="validationCustom02" className="form-label fs-5">
                  Applicant's Occupation <span className="text-danger">*</span>
                </label>
                <select required className="form-select" onBlur={() => handleBlur('candidate_occupation', occupation)} value={occupation} onChange={(e) => { setOccupation(e.target.value) }}>
                  <option disabled value="">
                    Select Occupation
                  </option>
                  {dbOccupationOption.map((val, key) => (
                    <option value={val} key={key}>{val}</option>
                  ))}
                </select> */}
              </div>
              <div className="col-md-4">
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Nature Of Work"
                  size="small"
                  style={{ width: "100%" }}
                  onBlur={() => handleBlur('nature_of_work', natureOfWork)}
                  value={natureOfWork}
                  onChange={(e) => { setNatureOfWork(e.target.value) }}
                >
                  {dbNature_of_workOption.map((val, key) => (
                    <MenuItem key={val} value={val}>
                      {val}
                    </MenuItem>
                  ))}
                </TextField>
                {/* <label htmlFor="validationCustom02" className="form-label fs-5">
                  Nature Of Work
                </label>
                <select className="form-select" onBlur={() => handleBlur('nature_of_work', natureOfWork)} value={natureOfWork} onChange={(e) => { setNatureOfWork(e.target.value) }} >
                  <option disabled value="">
                    Select Nature Of Work
                  </option>
                  {dbNature_of_workOption.map((val, key) => (
                    <option value={val} key={key}>{val}</option>
                  ))}
                </select> */}
              </div>
              <div className="col-md-2">
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Currency"
                  size="small"
                  required
                  style={{ width: "100%" }}
                  onBlur={() => handleBlur('currency', currency)}
                  value={currency}
                  onChange={(e) => { setCurrency(e.target.value) }}
                >
                  {dbCurrencyOption.map((val, key) => (
                    <MenuItem key={val} value={val}>
                      {val}
                    </MenuItem>
                  ))}
                </TextField>
                {/* <label
                  htmlFor="validationCustomUsername"
                  className="form-label fs-5"
                >
                  Currency <span className="text-danger">*</span>
                </label>
                <select required className="form-select" onBlur={() => handleBlur('currency', currency)} value={currency} onChange={(e) => { setCurrency(e.target.value) }} >
                  <option disabled value="">
                    Select Currency
                  </option>
                  {dbCurrencyOption.map((val, key) => (
                    <option value={val} key={key}>{val}</option>
                  ))}
                </select> */}
              </div>
              <div className="col-md-3">
                <TextField
                  required
                  id="outlined-basic"
                  variant="outlined"
                  type="number"
                  size="small"
                  label="Applicant's Annual Income"
                  style={{ width: "100%" }}
                  onChange={(e) => { setCandidateAnnualIncome(e.target.value) }}
                  value={candidateAnnualIncome}
                  onBlur={() => handleBlur('candidate_annual_income', candidateAnnualIncome)}
                />
                {/* <label
                  htmlFor="validationCustomUsername"
                  className="form-label fs-5"
                >
                  Applicant's Annual Income <span className="text-danger">*</span>
                </label>
                <div className="input-group has-validation">
                  <input
                    type="number"
                    className="form-control"
                    id="validationCustomUsername"
                    aria-describedby="inputGroupPrepend"
                    required
                    onChange={(e) => { setCandidateAnnualIncome(e.target.value) }}
                    value={candidateAnnualIncome}
                    onBlur={() => handleBlur('candidate_annual_income', candidateAnnualIncome)}
                  />
                  <div className="invalid-feedback">Please choose a username.</div>
                </div> */}
              </div>

              <div className="mt-3">
                <div className="h4 pb-2 mb-4 text-primary border-bottom border-primary">
                  Family & Relatives
                </div>

                <div className="row g-3">
                  <div className="col-md-2">
                    <TextField
                      value="Father"
                      disabled
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      style={{ width: "100%", background: "#e9ecef" }}
                    />
                    {/* <input
                      className="form-control"
                      type="text"
                      value="Father"
                      disabled
                    /> */}
                  </div>
                  <div className="col-md-3">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Father Name"
                      style={{ width: "100%" }}
                      onChange={(e) => { setFatherName(e.target.value) }}
                      value={fatherName}
                      onBlur={() => familyhandleBlur(fatherRowId, 'relative_name', fatherName)}
                    />
                    {/* <input
                      className="form-control"
                      type="text"
                      placeholder="Father Name"
                      onChange={(e) => { setFatherName(e.target.value) }}
                      value={fatherName}
                      onBlur={() => familyhandleBlur(fatherRowId, 'relative_name', fatherName)}
                    /> */}
                  </div>
                  <div className="col-md-2">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="number"
                      size="small"
                      label="Father Age"
                      style={{ width: "100%" }}
                      onChange={(e) => { setFatherAge(e.target.value) }}
                      value={fatherAge}
                      onBlur={() => familyhandleBlur(fatherRowId, 'relative_age', fatherAge)}
                    />
                    {/* <input
                      className="form-control"
                      type="number"
                      placeholder="Father Age"
                      onChange={(e) => { setFatherAge(e.target.value) }}
                      value={fatherAge}
                      onBlur={() => familyhandleBlur(fatherRowId, 'relative_age', fatherAge)}
                    /> */}
                  </div>
                  <div className="col-md-2">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Qualification"
                      size="small"
                      style={{ width: "100%" }}
                      onBlur={() => familyhandleBlur(fatherRowId, 'relative_qualification', fatherQualification)}
                      value={fatherQualification}
                      onChange={(e) => { setFatherQualification(e.target.value) }}
                    >
                      {dbQualificationOption.map((val, key) => (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </TextField>
                    {/* <select className="form-select" onBlur={() => familyhandleBlur(fatherRowId, 'relative_qualification', fatherQualification)} value={fatherQualification} onChange={(e) => { setFatherQualification(e.target.value) }}>
                      <option disabled value="">
                        Qualification
                      </option>
                      {dbQualificationOption.map((val, key) => (
                        <option value={val} key={key}>{val}</option>
                      ))}
                    </select> */}
                  </div>
                  <div className="col-md-3">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Occupation"
                      size="small"
                      style={{ width: "100%" }}
                      onBlur={() => familyhandleBlur(fatherRowId, 'relative_occupation', fatherOccupation)}
                      value={fatherOccupation}
                      onChange={(e) => { setFatherOccupation(e.target.value) }}
                    >
                      {dbOccupationOption.map((val, key) => (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </TextField>
                    {/* <select className="form-select" onBlur={() => familyhandleBlur(fatherRowId, 'relative_occupation', fatherOccupation)} value={fatherOccupation} onChange={(e) => { setFatherOccupation(e.target.value) }}>
                      <option disabled value="">
                        Occupation
                      </option>
                      {dbOccupationOption.map((val, key) => (
                        <option value={val} key={key}>{val}</option>
                      ))}
                    </select> */}
                  </div>


                  <hr className="d-md-none" />

                  <div className="col-md-2">
                    <TextField
                      disabled
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      size="small"
                      style={{ width: "100%", background: "#e9ecef" }}
                      value="Mother"
                    />
                    {/* <input
                      className="form-control"
                      type="text"
                      value="Mother"
                      disabled
                    /> */}
                  </div>
                  <div className="col-md-3">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Mother Name"
                      style={{ width: "100%" }}
                      onChange={(e) => { setMotherName(e.target.value) }}
                      value={motherName}
                      onBlur={() => familyhandleBlur(motherRowId, 'relative_name', motherName)}
                    />
                    {/* <input
                      className="form-control"
                      type="text"
                      placeholder="Mother Name"
                      onChange={(e) => { setMotherName(e.target.value) }}
                      value={motherName}
                      onBlur={() => familyhandleBlur(motherRowId, 'relative_name', motherName)}
                    /> */}
                  </div>
                  <div className="col-md-2">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="number"
                      size="small"
                      label="Mother Age"
                      style={{ width: "100%" }}
                      onChange={(e) => { setMotherAge(e.target.value) }}
                      value={motherAge}
                      onBlur={() => familyhandleBlur(motherRowId, 'relative_age', motherAge)}
                    />
                    {/* <input
                      className="form-control"
                      type="number"
                      placeholder="Mother Age"
                      onChange={(e) => { setMotherAge(e.target.value) }}
                      value={motherAge}
                      onBlur={() => familyhandleBlur(motherRowId, 'relative_age', motherAge)}
                    /> */}
                  </div>
                  <div className="col-md-2">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Qualification"
                      size="small"
                      style={{ width: "100%" }}
                      onBlur={() => familyhandleBlur(motherRowId, 'relative_qualification', motherQualification)}
                      value={motherQualification}
                      onChange={(e) => { setMotherQualification(e.target.value) }}
                    >
                      {dbQualificationOption.map((val, key) => (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </TextField>
                    {/* <select className="form-select" onBlur={() => familyhandleBlur(motherRowId, 'relative_qualification', motherQualification)} value={motherQualification} onChange={(e) => { setMotherQualification(e.target.value) }}>
                      <option disabled value="">
                        Qualification
                      </option>
                      {dbQualificationOption.map((val, key) => (
                        <option value={val} key={key}>{val}</option>
                      ))}
                    </select> */}
                  </div>
                  <div className="col-md-3">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Occupation"
                      size="small"
                      style={{ width: "100%" }}
                      onBlur={() => familyhandleBlur(motherRowId, 'relative_occupation', motherOccupation)}
                      value={motherOccupation}
                      onChange={(e) => { setMotherOccupation(e.target.value) }}
                    >
                      {dbOccupationOption.map((val, key) => (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </TextField>
                    {/* <select className="form-select" onBlur={() => familyhandleBlur(motherRowId, 'relative_occupation', motherOccupation)} value={motherOccupation} onChange={(e) => { setMotherOccupation(e.target.value) }}>
                      <option disabled value="">
                        Occupation
                      </option>
                      {dbOccupationOption.map((val, key) => (
                        <option value={val} key={key}>{val}</option>
                      ))}
                    </select> */}
                  </div>

                  <hr className="d-md-none" />

                  <div className="col-md-2">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Relationship"
                      size="small"
                      style={{ width: "100%" }}
                      onBlur={() => familyhandleBlur(relative3RowId, 'relation_ship', relative3Relation)}
                      value={relative3Relation}
                      onChange={(e) => { setRelative3Relation(e.target.value) }}
                    >
                      <MenuItem value="">None</MenuItem>
                      {dbRelationshipOption.map((val, key) => (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </TextField>
                    {/* <select className="form-select" onBlur={() => familyhandleBlur(relative3RowId, 'relation_ship', relative3Relation)} value={relative3Relation} onChange={(e) => { setRelative3Relation(e.target.value) }}>
                      <option disabled value="">
                        Relationship
                      </option>
                      {dbRelationshipOption.map((val, key) => (
                        <option value={val} key={key}>{val}</option>
                      ))}
                    </select> */}
                  </div>
                  <div className="col-md-3">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      size="small"
                      label="His/Her Name"
                      style={{ width: "100%" }}
                      onChange={(e) => { setRelative3Name(e.target.value) }}
                      value={relative3Name}
                      onBlur={() => familyhandleBlur(relative3RowId, 'relative_name', relative3Name)}
                    />
                    {/* <input
                      className="form-control"
                      type="text"
                      placeholder="His/Her Name"
                      onChange={(e) => { setRelative3Name(e.target.value) }}
                      value={relative3Name}
                      onBlur={() => familyhandleBlur(relative3RowId, 'relative_name', relative3Name)}
                    /> */}
                  </div>
                  <div className="col-md-2">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="number"
                      size="small"
                      label="His/Her Age"
                      style={{ width: "100%" }}
                      onChange={(e) => { setRelative3Age(e.target.value) }}
                      value={relative3Age}
                      onBlur={() => familyhandleBlur(relative3RowId, 'relative_age', relative3Age)}
                    />
                    {/* <input
                      className="form-control"
                      type="number"
                      placeholder="His/Her Age"
                      onChange={(e) => { setRelative3Age(e.target.value) }}
                      value={relative3Age}
                      onBlur={() => familyhandleBlur(relative3RowId, 'relative_age', relative3Age)}
                    /> */}
                  </div>
                  <div className="col-md-2">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Qualification"
                      size="small"
                      style={{ width: "100%" }}
                      onBlur={() => familyhandleBlur(relative3RowId, 'relative_qualification', relative3Qualification)}
                      value={relative3Qualification}
                      onChange={(e) => { setRelative3Qualification(e.target.value) }}
                    >
                      {dbQualificationOption.map((val, key) => (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </TextField>
                    {/* <select className="form-select" onBlur={() => familyhandleBlur(relative3RowId, 'relative_qualification', relative3Qualification)} value={relative3Qualification} onChange={(e) => { setRelative3Qualification(e.target.value) }}>
                      <option disabled value="">
                        Qualification
                      </option>
                      {dbQualificationOption.map((val, key) => (
                        <option value={val} key={key}>{val}</option>
                      ))}
                    </select> */}
                  </div>
                  <div className="col-md-3">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Occupation"
                      size="small"
                      style={{ width: "100%" }}
                      onBlur={() => familyhandleBlur(relative3RowId, 'relative_occupation', relative3Occupation)}
                      value={relative3Occupation}
                      onChange={(e) => { setRelative3Occupation(e.target.value) }}
                    >
                      {dbOccupationOption.map((val, key) => (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </TextField>
                    {/* <select className="form-select" onBlur={() => familyhandleBlur(relative3RowId, 'relative_occupation', relative3Occupation)} value={relative3Occupation} onChange={(e) => { setRelative3Occupation(e.target.value) }}>
                      <option disabled value="">
                        Occupation
                      </option>
                      {dbOccupationOption.map((val, key) => (
                        <option value={val} key={key}>{val}</option>
                      ))}
                    </select> */}
                  </div>

                  <hr className="d-md-none" />

                  <div className="col-md-2">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Relationship"
                      size="small"
                      style={{ width: "100%" }}
                      onBlur={() => familyhandleBlur(relative4RowId, 'relation_ship', relative4Relation)}
                      value={relative4Relation}
                      onChange={(e) => { setRelative4Relation(e.target.value) }}
                    >
                      <MenuItem value="">None</MenuItem>
                      {dbRelationshipOption.map((val, key) => (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </TextField>
                    {/* <select className="form-select" onBlur={() => familyhandleBlur(relative4RowId, 'relation_ship', relative4Relation)} value={relative4Relation} onChange={(e) => { setRelative4Relation(e.target.value) }}>
                      <option disabled value="">
                        Relationship
                      </option>
                      {dbRelationshipOption.map((val, key) => (
                        <option value={val} key={key}>{val}</option>
                      ))}
                    </select> */}
                  </div>
                  <div className="col-md-3">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      size="small"
                      label="His/Her Name"
                      style={{ width: "100%" }}
                      onChange={(e) => { setRelative4Name(e.target.value) }}
                      value={relative4Name}
                      onBlur={() => familyhandleBlur(relative4RowId, 'relative_name', relative4Name)}
                    />
                    {/* <input
                      className="form-control"
                      type="text"
                      placeholder="His/Her Name"
                      onChange={(e) => { setRelative4Name(e.target.value) }}
                      value={relative4Name}
                      onBlur={() => familyhandleBlur(relative4RowId, 'relative_name', relative4Name)}
                    /> */}
                  </div>
                  <div className="col-md-2">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="number"
                      size="small"
                      label="His/Her Age"
                      style={{ width: "100%" }}
                      onChange={(e) => { setRelative4Age(e.target.value) }}
                      value={relative4Age}
                      onBlur={() => familyhandleBlur(relative4RowId, 'relative_age', relative4Age)}
                    />
                    {/* <input
                      className="form-control"
                      type="number"
                      placeholder="His/Her Age"
                      onChange={(e) => { setRelative4Age(e.target.value) }}
                      value={relative4Age}
                      onBlur={() => familyhandleBlur(relative4RowId, 'relative_age', relative4Age)}
                    /> */}
                  </div>
                  <div className="col-md-2">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Qualification"
                      size="small"
                      style={{ width: "100%" }}
                      onBlur={() => familyhandleBlur(relative4RowId, 'relative_qualification', relative4Qualification)}
                      value={relative4Qualification}
                      onChange={(e) => { setRelative4Qualification(e.target.value) }}
                    >
                      {dbQualificationOption.map((val, key) => (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </TextField>
                    {/* <select className="form-select" onBlur={() => familyhandleBlur(relative4RowId, 'relative_qualification', relative4Qualification)} value={relative4Qualification} onChange={(e) => { setRelative4Qualification(e.target.value) }}>
                      <option disabled value="">
                        Qualification
                      </option>
                      {dbQualificationOption.map((val, key) => (
                        <option value={val} key={key}>{val}</option>
                      ))}
                    </select> */}
                  </div>
                  <div className="col-md-3">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Occupation"
                      size="small"
                      style={{ width: "100%" }}
                      onBlur={() => familyhandleBlur(relative4RowId, 'relative_occupation', relative4Occupation)}
                      value={relative4Occupation}
                      onChange={(e) => { setRelative4Occupation(e.target.value) }}
                    >
                      {dbOccupationOption.map((val, key) => (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </TextField>
                    {/* <select className="form-select" onBlur={() => familyhandleBlur(relative4RowId, 'relative_occupation', relative4Occupation)} value={relative4Occupation} onChange={(e) => { setRelative4Occupation(e.target.value) }}>
                      <option disabled value="">
                        Occupation
                      </option>
                      {dbOccupationOption.map((val, key) => (
                        <option value={val} key={key}>{val}</option>
                      ))}
                    </select> */}
                  </div>

                  <hr className="d-md-none" />

                  <div className="col-md-2">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Relationship"
                      size="small"
                      style={{ width: "100%" }}
                      onBlur={() => familyhandleBlur(relative5RowId, 'relation_ship', relative5Relation)}
                      value={relative5Relation}
                      onChange={(e) => { setRelative5Relation(e.target.value) }}
                    >
                      <MenuItem value="">None</MenuItem>
                      {dbRelationshipOption.map((val, key) => (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </TextField>
                    {/* <select className="form-select" onBlur={() => familyhandleBlur(relative5RowId, 'relation_ship', relative5Relation)} value={relative5Relation} onChange={(e) => { setRelative5Relation(e.target.value) }}>
                      <option disabled value="">
                        Relationship
                      </option>
                      {dbRelationshipOption.map((val, key) => (
                        <option value={val} key={key}>{val}</option>
                      ))}
                    </select> */}
                  </div>
                  <div className="col-md-3">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      size="small"
                      label="His/Her Name"
                      style={{ width: "100%" }}
                      onChange={(e) => { setRelative5Name(e.target.value) }}
                      value={relative5Name}
                      onBlur={() => familyhandleBlur(relative5RowId, 'relative_name', relative5Name)}
                    />
                    {/* <input
                      className="form-control"
                      type="text"
                      placeholder="His/Her Name"
                      onChange={(e) => { setRelative5Name(e.target.value) }}
                      value={relative5Name}
                      onBlur={() => familyhandleBlur(relative5RowId, 'relative_name', relative5Name)}
                    /> */}
                  </div>
                  <div className="col-md-2">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="number"
                      size="small"
                      label="His/Her Age"
                      style={{ width: "100%" }}
                      onChange={(e) => { setRelative5Age(e.target.value) }}
                      value={relative5Age}
                      onBlur={() => familyhandleBlur(relative5RowId, 'relative_age', relative5Age)}
                    />
                    {/* <input
                      className="form-control"
                      type="number"
                      placeholder="His/Her Age"
                      onChange={(e) => { setRelative5Age(e.target.value) }}
                      value={relative5Age}
                      onBlur={() => familyhandleBlur(relative5RowId, 'relative_age', relative5Age)}
                    /> */}
                  </div>
                  <div className="col-md-2">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Qualification"
                      size="small"
                      style={{ width: "100%" }}
                      onBlur={() => familyhandleBlur(relative5RowId, 'relative_qualification', relative5Qualification)}
                      value={relative5Qualification}
                      onChange={(e) => { setRelative5Qualification(e.target.value) }}
                    >
                      {dbQualificationOption.map((val, key) => (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </TextField>
                    {/* <select className="form-select" onBlur={() => familyhandleBlur(relative5RowId, 'relative_qualification', relative5Qualification)} value={relative5Qualification} onChange={(e) => { setRelative5Qualification(e.target.value) }}>
                      <option disabled value="">
                        Qualification
                      </option>
                      {dbQualificationOption.map((val, key) => (
                        <option value={val} key={key}>{val}</option>
                      ))}
                    </select> */}
                  </div>
                  <div className="col-md-3">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Occupation"
                      size="small"
                      style={{ width: "100%" }}
                      onBlur={() => familyhandleBlur(relative5RowId, 'relative_occupation', relative5Occupation)}
                      value={relative5Occupation}
                      onChange={(e) => { setRelative5Occupation(e.target.value) }}
                    >
                      {dbOccupationOption.map((val, key) => (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </TextField>
                    {/* <select className="form-select" onBlur={() => familyhandleBlur(relative5RowId, 'relative_occupation', relative5Occupation)} value={relative5Occupation} onChange={(e) => { setRelative5Occupation(e.target.value) }}>
                      <option disabled value="">
                        Occupation
                      </option>
                      {dbOccupationOption.map((val, key) => (
                        <option value={val} key={key}>{val}</option>
                      ))}
                    </select> */}
                  </div>

                  <hr className="d-md-none" />

                  <div className="col-md-2">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Relationship"
                      size="small"
                      style={{ width: "100%" }}
                      onBlur={() => familyhandleBlur(relative6RowId, 'relation_ship', relative6Relation)}
                      value={relative6Relation}
                      onChange={(e) => { setRelative6Relation(e.target.value) }}
                    >
                      <MenuItem value="">None</MenuItem>
                      {dbRelationshipOption.map((val, key) => (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </TextField>
                    {/* <select className="form-select" onBlur={() => familyhandleBlur(relative6RowId, 'relation_ship', relative6Relation)} value={relative6Relation} onChange={(e) => { setRelative6Relation(e.target.value) }}>
                      <option disabled value="">
                        Relationship
                      </option>
                      {dbRelationshipOption.map((val, key) => (
                        <option value={val} key={key}>{val}</option>
                      ))}
                    </select> */}
                  </div>
                  <div className="col-md-3">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      size="small"
                      label="His/Her Name"
                      style={{ width: "100%" }}
                      onChange={(e) => { setRelative6Name(e.target.value) }}
                      value={relative6Name}
                      onBlur={() => familyhandleBlur(relative6RowId, 'relative_name', relative6Name)}
                    />
                    {/* <input
                      className="form-control"
                      type="text"
                      placeholder="His/Her Name"
                      onChange={(e) => { setRelative6Name(e.target.value) }}
                      value={relative6Name}
                      onBlur={() => familyhandleBlur(relative6RowId, 'relative_name', relative6Name)}
                    /> */}
                  </div>
                  <div className="col-md-2">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="number"
                      size="small"
                      label="His/Her Age"
                      style={{ width: "100%" }}
                      onChange={(e) => { setRelative6Age(e.target.value) }}
                      value={relative6Age}
                      onBlur={() => familyhandleBlur(relative6RowId, 'relative_age', relative6Age)}
                    />
                    {/* <input
                      className="form-control"
                      type="number"
                      placeholder="His/Her Age"
                      onChange={(e) => { setRelative6Age(e.target.value) }}
                      value={relative6Age}
                      onBlur={() => familyhandleBlur(relative6RowId, 'relative_age', relative6Age)}
                    /> */}
                  </div>
                  <div className="col-md-2">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Qualification"
                      size="small"
                      style={{ width: "100%" }}
                      onBlur={() => familyhandleBlur(relative6RowId, 'relative_qualification', relative6Qualification)}
                      value={relative6Qualification}
                      onChange={(e) => { setRelative6Qualification(e.target.value) }}
                    >
                      {dbQualificationOption.map((val, key) => (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </TextField>
                    {/* <select className="form-select" onBlur={() => familyhandleBlur(relative6RowId, 'relative_qualification', relative6Qualification)} value={relative6Qualification} onChange={(e) => { setRelative6Qualification(e.target.value) }}>
                      <option disabled value="">
                        Qualification
                      </option>
                      {dbQualificationOption.map((val, key) => (
                        <option value={val} key={key}>{val}</option>
                      ))}
                    </select> */}
                  </div>
                  <div className="col-md-3">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Occupation"
                      size="small"
                      style={{ width: "100%" }}
                      onBlur={() => familyhandleBlur(relative6RowId, 'relative_occupation', relative6Occupation)}
                      value={relative6Occupation}
                      onChange={(e) => { setRelative6Occupation(e.target.value) }}
                    >
                      {dbOccupationOption.map((val, key) => (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </TextField>
                    {/* <select className="form-select" onBlur={() => familyhandleBlur(relative6RowId, 'relative_occupation', relative6Occupation)} value={relative6Occupation} onChange={(e) => { setRelative6Occupation(e.target.value) }}>
                      <option disabled value="">
                        Occupation
                      </option>
                      {dbOccupationOption.map((val, key) => (
                        <option value={val} key={key}>{val}</option>
                      ))}
                    </select> */}
                  </div>

                  <hr className="d-md-none" />


                  <div className="col-md-2">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Relationship"
                      size="small"
                      style={{ width: "100%" }}
                      onBlur={() => familyhandleBlur(relative7RowId, 'relation_ship', relative7Relation)}
                      value={relative7Relation}
                      onChange={(e) => { setRelative7Relation(e.target.value) }}
                    >
                      <MenuItem value="">None</MenuItem>
                      {dbRelationshipOption.map((val, key) => (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </TextField>
                    {/* <select className="form-select" onBlur={() => familyhandleBlur(relative7RowId, 'relation_ship', relative7Relation)} value={relative7Relation} onChange={(e) => { setRelative7Relation(e.target.value) }}>
                      <option disabled value="">
                        Relationship
                      </option>
                      {dbRelationshipOption.map((val, key) => (
                        <option value={val} key={key}>{val}</option>
                      ))}
                    </select> */}
                  </div>
                  <div className="col-md-3">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      size="small"
                      label="His/Her Name"
                      style={{ width: "100%" }}
                      onChange={(e) => { setRelative7Name(e.target.value) }}
                      value={relative7Name}
                      onBlur={() => familyhandleBlur(relative7RowId, 'relative_name', relative7Name)}
                    />
                    {/* <input
                      className="form-control"
                      type="text"
                      placeholder="His/Her Name"
                      onChange={(e) => { setRelative7Name(e.target.value) }}
                      value={relative7Name}
                      onBlur={() => familyhandleBlur(relative7RowId, 'relative_name', relative7Name)}
                    /> */}
                  </div>
                  <div className="col-md-2">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="number"
                      size="small"
                      label="His/Her Age"
                      style={{ width: "100%" }}
                      onChange={(e) => { setRelative7Age(e.target.value) }}
                      value={relative7Age}
                      onBlur={() => familyhandleBlur(relative7RowId, 'relative_age', relative7Age)}
                    />
                    {/* <input
                      className="form-control"
                      type="number"
                      placeholder="His/Her Age"
                      onChange={(e) => { setRelative7Age(e.target.value) }}
                      value={relative7Age}
                      onBlur={() => familyhandleBlur(relative7RowId, 'relative_age', relative7Age)}
                    /> */}
                  </div>
                  <div className="col-md-2">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Qualification"
                      size="small"
                      style={{ width: "100%" }}
                      onBlur={() => familyhandleBlur(relative7RowId, 'relative_qualification', relative7Qualification)}
                      value={relative7Qualification}
                      onChange={(e) => { setRelative7Qualification(e.target.value) }}
                    >
                      {dbQualificationOption.map((val, key) => (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </TextField>
                    {/* <select className="form-select" onBlur={() => familyhandleBlur(relative7RowId, 'relative_qualification', relative7Qualification)} value={relative7Qualification} onChange={(e) => { setRelative7Qualification(e.target.value) }}>
                      <option disabled value="">
                        Qualification
                      </option>
                      {dbQualificationOption.map((val, key) => (
                        <option value={val} key={key}>{val}</option>
                      ))}
                    </select> */}
                  </div>
                  <div className="col-md-3">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Occupation"
                      size="small"
                      style={{ width: "100%" }}
                      onBlur={() => familyhandleBlur(relative7RowId, 'relative_occupation', relative7Occupation)}
                      value={relative7Occupation}
                      onChange={(e) => { setRelative7Occupation(e.target.value) }}
                    >
                      {dbOccupationOption.map((val, key) => (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </TextField>
                    {/* <select className="form-select" onBlur={() => familyhandleBlur(relative7RowId, 'relative_occupation', relative7Occupation)} value={relative7Occupation} onChange={(e) => { setRelative7Occupation(e.target.value) }}>
                      <option disabled value="">
                        Occupation
                      </option>
                      {dbOccupationOption.map((val, key) => (
                        <option value={val} key={key}>{val}</option>
                      ))}
                    </select> */}
                  </div>

                  <hr className="d-md-none" />

                  <div className="col-md-9">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Father's / Family's Permanent Address"
                      style={{ width: "100%", marginTop: "7px" }}
                      onChange={(e) => { setFamilyPermanentAddress(e.target.value) }}
                      value={familyPermanentAddress}
                      onBlur={() => handleBlur('family_permanent_address', familyPermanentAddress)}
                    />
                    {/* <label htmlFor="validationCustom02" className="form-label fs-5">
                      Father's / Family's Permanent Address
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      onChange={(e) => { setFamilyPermanentAddress(e.target.value) }}
                      value={familyPermanentAddress}
                      onBlur={() => handleBlur('family_permanent_address', familyPermanentAddress)}
                    /> */}
                  </div>
                  <div className="col-md-3">
                    <TextField
                      type="number"
                      label="Family's Annual Income"
                      id="outlined-start-adornment"
                      sx={{ width: '100%', marginTop: "7px" }}
                      size="small"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                      }}
                      onChange={(e) => { setFamilyAnnualIncome(e.target.value) }}
                      value={familyAnnualIncome}
                      onBlur={() => handleBlur('family_annual_income', familyAnnualIncome)}
                    />
                    {/* <label
                      htmlFor="validationCustomUsername"
                      className="form-label fs-5"
                    >
                      Family's Annual Income
                    </label>
                    <div className="input-group has-validation">
                      <span className="input-group-text" id="inputGroupPrepend">
                        ₹
                      </span>
                      <input
                        type="number"
                        className="form-control"
                        id="validationCustomUsername"
                        aria-describedby="inputGroupPrepend"
                        onChange={(e) => { setFamilyAnnualIncome(e.target.value) }}
                        value={familyAnnualIncome}
                        onBlur={() => handleBlur('family_annual_income', familyAnnualIncome)}
                      />
                      <div className="invalid-feedback">
                        Please choose a username.
                      </div>
                    </div> */}
                  </div>

                  <div className="h4 pb-2 mb-1 text-primary border-bottom border-primary">
                    Gurudwara Details
                  </div>

                  <div className="col-md-6">
                    <TextField
                      required
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Gurudwara Normally visited : Name & Address"
                      style={{ width: "100%" }}
                      onChange={(e) => { setGurudwaraNormallyVisited(e.target.value) }}
                      value={gurudwaraNormallyVisited}
                      onBlur={() => handleBlur('gurudwara_normally_visited', gurudwaraNormallyVisited)}
                    />
                    {/* <label htmlFor="validationCustom02" className="form-label">
                      Gurudwara Normally visited : Name & Address{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      onChange={(e) => { setGurudwaraNormallyVisited(e.target.value) }}
                      value={gurudwaraNormallyVisited}
                      onBlur={() => handleBlur('gurudwara_normally_visited', gurudwaraNormallyVisited)}
                      required
                    /> */}
                  </div>
                  <div className="col-md-3">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Contact Person Name"
                      style={{ width: "100%" }}
                      onChange={(e) => { setGurudwaraContactName(e.target.value) }}
                      value={gurudwaraContactName}
                      onBlur={() => handleBlur('gurudwara_contact_person', gurudwaraContactName)}
                    />
                    {/* <label htmlFor="validationCustom02" className="form-label">
                      Name of Contact Person at Gurudwara
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      onChange={(e) => { setGurudwaraContactName(e.target.value) }}
                      value={gurudwaraContactName}
                      onBlur={() => handleBlur('gurudwara_contact_person', gurudwaraContactName)}
                    /> */}
                  </div>
                  <div className="col-md-3">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="number"
                      size="small"
                      label="Phone no. of contact person"
                      style={{ width: "100%" }}
                      onChange={(e) => { setGurudwaraContactNumber(e.target.value) }}
                      value={gurudwaraContactNumber}
                      onBlur={() => handleBlur('gurudwara_phone_number', gurudwaraContactNumber)}
                    />
                    {/* <label htmlFor="validationCustom02" className="form-label">
                      Phone no. of contact person
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      onChange={(e) => { setGurudwaraContactNumber(e.target.value) }}
                      value={gurudwaraContactNumber}
                      onBlur={() => handleBlur('gurudwara_phone_number', gurudwaraContactNumber)}
                    /> */}
                  </div>

                  <div className="h4 pb-2 mb-2 text-primary border-bottom border-primary">
                    Any Other Information
                  </div>

                  <div className="col-md-12">
                    <TextField
                      id="outlined-multiline-static"
                      label="Other Information"
                      multiline
                      rows={2}
                      style={{ width: "100%" }}
                      onChange={(e) => { setAnyOtherInfo(e.target.value) }}
                      value={anyOtherInfo}
                      onBlur={() => handleBlur('additional_information', anyOtherInfo)}
                    />
                    {/* <textarea
                      className="form-control"
                      onChange={(e) => { setAnyOtherInfo(e.target.value) }}
                      value={anyOtherInfo}
                      onBlur={() => handleBlur('additional_information', anyOtherInfo)}
                    ></textarea> */}
                  </div>

                  <div className="container text-primary border-bottom border-primary mt-3">
                    <div className="row">
                      <div className="col-5 h4 d-flex align-items-center">
                        <p className="mb-0">Reference details:</p>
                      </div>
                      <div className="col text-right d-flex align-items-center">
                        <i className="bi bi-exclamation-triangle-fill text-warning mx-3"></i>
                        <p className="mb-0">
                          Kindly note: Your profile will be published only after the
                          reference person confirms.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <TextField
                      required
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Reference Person Name"
                      style={{ width: "100%" }}
                      onChange={(e) => { setReferencePersonName(e.target.value) }}
                      value={referencePersonName}
                      onBlur={() => handleBlur('reference_name', referencePersonName)}
                    />
                    {/* <label htmlFor="validationCustom02" className="form-label">
                      Reference Person Name <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      onChange={(e) => { setReferencePersonName(e.target.value) }}
                      value={referencePersonName}
                      onBlur={() => handleBlur('reference_name', referencePersonName)}
                    /> */}
                  </div>
                  <div className="col-md-3">
                    <TextField
                      required
                      id="outlined-basic"
                      variant="outlined"
                      type="number"
                      size="small"
                      label="Reference WhatsApp Number"
                      style={{ width: "100%" }}
                      onChange={(e) => { setReferencePersonWAN(e.target.value) }}
                      value={referencePersonWAN}
                      onBlur={() => handleBlur('reference_contact', referencePersonWAN)}
                    />
                    {/* <label htmlFor="validationCustom02" className="form-label">
                      Reference WhatsApp Number <span className="text-danger"> *</span>
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      required
                      onChange={(e) => { setReferencePersonWAN(e.target.value) }}
                      value={referencePersonWAN}
                      onBlur={() => handleBlur('reference_contact', referencePersonWAN)}
                    /> */}
                  </div>



                  <div className="col-md-12">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        required
                      />
                      <label className="form-check-label" htmlFor="flexCheckDefault">
                        I have read and agree to the
                      </label>
                      <Link
                        to="ApplicantRegistration"
                        type="button"
                        className="mx-2"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        style={{ textDecoration: "" }}
                      >
                        Terms And Conditions
                      </Link>
                    </div>
                    <div
                      className="modal fade"
                      id="exampleModal"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h1 className="modal-title fs-5 text-primary" id="exampleModalLabel">
                              Global Sikh Matrimony
                            </h1>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body text-primary">
                            <ul>
                              <li>
                                It is important for the Candidates, Applicants and their family members to note that this matrimonial service is a free service to the applicants / candidates. The purpose of this service is to provide a common platform for the members of our community.
                              </li>
                              <br />
                              <li>
                                It is the responsibility of the applicant / candidate to verify the authenticity of the information provided by other applicants / candidates. The Sikh Foundation, members of the Sikh Foundation, the developers of this portal take no responsibility regarding the authenticity of the information provided by any applicant / candidate.
                              </li>
                            </ul>

                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-bs-dismiss="modal"
                            >
                              Ok
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mb-5">
                {/* save button */}
                {submitBtnLoading ? (
                  <button className="btn btn-primary" disabled>Please Wait...</button>
                ) : (
                  // <Link to="/fillapplication" className="btn btn-primary" onClick={() => { getOtpClicked(); }} >Get OTP</Link>
                  // <Link to="/fillapplication" className="btn btn-primary" onClick={() => { verifyOtpClicked(); }}>Verify OTP</Link>
                  <input
                    type="submit"
                    className="btn btn-primary"
                    value="Save and Proceed"
                  />
                )}
                <Link to="/" className="btn btn-primary mx-2" >Back</Link>
              </div>
              <Link to="/" className="btn btn-primary mb-4" data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-custom-class="custom-tooltip"
                data-bs-title="Continue Later" style={{ position: 'fixed', bottom: '20px', right: '20px', width: "50px" }} >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-floppy-fill" viewBox="0 0 16 16">
                  <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0H3v5.5A1.5 1.5 0 0 0 4.5 7h7A1.5 1.5 0 0 0 13 5.5V0h.086a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5H14v-5.5A1.5 1.5 0 0 0 12.5 9h-9A1.5 1.5 0 0 0 2 10.5V16h-.5A1.5 1.5 0 0 1 0 14.5z" />
                  <path d="M3 16h10v-5.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5zm9-16H4v5.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5zM9 1h2v4H9z" />
                </svg>
              </Link>
            </form>
          </div>
        )
        }
        {
          uploadPhoto && (
            <>
              <UploadPhoto data={{
                CandidateID: CandidateID,
                phoneNo: whatsappNumber,
                candidateFirstName: candidateFirstName,
                candidatelastName: candidateMiddleName,
                candidateMiddleName: candidateLastName,
                Gender: gender,
                referenceName: referencePersonName,
                referenceNumber: referencePersonWAN,
                isEdit: false,
                countofsendingreference: 0,
                // CandidateID: "968265",
                // phoneNo:"9374234166",
                // candidateFirstName:"rohit",
                // candidatelastName:"prahladbhai",
                // candidateMiddleName:"badgujar",
                // Gender:"Male",
                // referenceName:"",
                // referenceNumber:"",
                // isEdit:false,
                // countofsendingreference: 0,
              }} />
            </>
          )
        }
      </div >

      <footer className="fixed-bottom" style={{ color: "white", backgroundColor: '#99999b', padding: '5px', textAlign: 'center', fontSize: "13px" }}>{configTableContent.project_footer}</footer>
    </div >
  );
}

export default FillApplication;
