import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { ConfigTableContext } from '../../helpers/AuthContext'
import axios from 'axios'

const Captcha = () => {
  const history = useHistory();
  const { configTableContent } = useContext(ConfigTableContext);

  useEffect(() => {
    axios.post("https://gsm2-api.aajkaa.in/applicant/increasevisitcount").then((response) => {
      console.log(response.data)
    });
  }, [])

  const handleCaptchaChange = async (token) => {
    if (token) {
      try {
        axios.post("https://gsm2-api.aajkaa.in/applicant/increasecaptchavc")
        await new Promise((resolve) => setTimeout(resolve, 1000));
        localStorage.setItem('captcha', true);
        history.push('/');
      } catch (error) {
        alert(error.message);
        console.error(error);
      }
    }
  };

  return (
    <div>
      <h1 className="text-primary text-center mt-2">
        {configTableContent.project_header}
      </h1>
      <div id="loginmargin" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh", flexDirection: "column" }}>
        Click on I'm Not Robot
        <br />
        <form className="mt-2">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_SITE_KEY_NEW}
            onChange={handleCaptchaChange}
          />
        </form>
      </div>
      <footer className="fixed-bottom" style={{ color: "white", backgroundColor: '#99999b', padding: '5px', textAlign: 'center', fontSize: "13px" }}>{configTableContent.project_footer}</footer>
    </div>
  );
}

export default Captcha;
