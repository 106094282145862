import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom';
import { ConfigTableContext } from '../../helpers/AuthContext'
import pdf from './ApplicationFormPrintable221213.pdf'


const PrintApplication = () => {

  const history = useHistory();
  const { configTableContent } = useContext(ConfigTableContext);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA' || event.target.isContentEditable) {
        return;
      }
      switch (event.key) {
        case 'Backspace':
          event.preventDefault();
          history.push('/')
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const captchaVerified = localStorage.getItem('captcha');
    if (!captchaVerified) {
      history.push('/captcha')
    }
  }, []);

  const downloadPdf = () => {
    // Create an anchor element
    const link = document.createElement('a');
    link.href = pdf; // Set the href attribute to the PDF file path
    link.download = 'Blank Application Form.pdf'; // Set the download attribute
    link.click(); // Simulate a click event to trigger the download
  };



  return (
    <div>
      <h1 className="text-primary text-center mt-2">
        {configTableContent.project_header}
      </h1>
      <div className="container d-flex justify-content-between align-items-center mt-2">
        <Link to='/' className="btn btn-outline-primary">
          <i className="bi bi-caret-left-fill"></i> Back
        </Link>
        <h2 className="text-primary text-center flex-grow-1 m-0">Print Application Form</h2>
        <div className="empty-space" style={{ width: '100px' }}></div>
      </div>

      <div className='container'>
        <button className='btn btn-primary my-2' onClick={downloadPdf}>Download Blank Application Form</button>
        <iframe className='border border-5' src={pdf} style={{ width: "100%", height: "70vh" }} title="myFrame"></iframe>
      </div>


      <footer className="fixed-bottom" style={{ color: "white", backgroundColor: '#99999b', padding: '5px', textAlign: 'center', fontSize: "13px" }}>{configTableContent.project_footer}</footer>
    </div>
  )
}

export default PrintApplication
