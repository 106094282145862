import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';


function UploadPhoto(props) {

  const history = useHistory();

  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [image1, setImage1] = useState(null);
  const [imagePreview1, setImagePreview1] = useState(null);
  const inputRef1 = useRef();
  const inputRef2 = useRef();

  const [oldphoto, setOldPhoto] = useState("")
  const [oldphoto1, setOldPhoto1] = useState("")

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);

    // Preview image
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (selectedImage) {
      reader.readAsDataURL(selectedImage);
    }
  };

  const handleImageChange1 = (e) => {
    const selectedImage = e.target.files[0];
    setImage1(selectedImage);

    // Preview image
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview1(reader.result);
    };
    if (selectedImage) {
      reader.readAsDataURL(selectedImage);
    }
  };

  const handleRemoveImage = (index) => {
    if (index === 1) {
      setImage(null);
      setImagePreview(null);
      if (inputRef1.current) {
        inputRef1.current.value = null; // Reset file input
      }
    } else if (index === 2) {
      setImage1(null);
      setImagePreview1(null);
      if (inputRef2.current) {
        inputRef2.current.value = null; // Reset file input
      }
    }
  };


  const formhandler = async (e) => {
    let err = null;
    e.preventDefault();
    if (!props.data.isEdit) {
      axios.post("https://gsm2-api.aajkaa.in/applicant/sendingreference", {
        CandidateID: props.data.CandidateID,
        phoneNo: props.data.phoneNo,
        candidateFirstName: props.data.candidateFirstName,
        candidateMiddleName: props.data.candidateMiddleName,
        candidatelastName: props.data.candidatelastName,
        referenceName: props.data.referenceName,
        referenceNumber: props.data.referenceNumber,
        Gender: props.data.Gender,
      }).then(() => {
        setShow(true);
        setModalMessage("Your application has been saved. It will be visible to other candidates after the approval of the reference person.");
      })
    } else {
      axios.post("https://gsm2-api.aajkaa.in/applicant/sendingreferencefromedit", {
        CandidateID: props.data.CandidateID,
        phoneNo: props.data.phoneNo,
        candidateFirstName: props.data.candidateFirstName,
        candidateMiddleName: props.data.candidateMiddleName,
        candidatelastName: props.data.candidatelastName,
        referenceName: props.data.referenceName,
        referenceNumber: props.data.referenceNumber,
        Gender: props.data.Gender,
        countofsendingreference: props.data.countofsendingreference,
      }).then(() => {
        setShow(true);
        setModalMessage("Your application has been saved. It will be visible to other candidates after the approval of the reference person.");
      })
    }
    try {
      const formData = new FormData();

      // Rename the images before appending them
      const renamedImage = new File([image], uploadedpp, { type: image.type });
      const renamedImage1 = new File([image1], uploadedfp, { type: image1.type });

      formData.append('image', renamedImage);
      formData.append('image', renamedImage1);
      try {
        const response = await axios.post('https://gsm2-api.aajkaa.in/uploadscandidatesphotos', formData);

        // Send the filenames to another route for further processing
        await axios.post('https://gsm2-api.aajkaa.in/applicant/updatecandidateimageinfo', {
          imagedata: response.data,
          candidateid: props.data.CandidateID
        }).then((res) => {
        });

      } catch (error) {
        console.error('Error uploading image:', error.message);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        setShow(true);
        setModalMessage(error.response.data.message);
      }
      err = error;
    }

  };

  const [a, setA] = useState("")
  const [b, setB] = useState("")
  const [uploadedpp, setUploadedpp] = useState("")
  const [uploadedfp, setUploadedfp] = useState("")

  useEffect(() => {
    axios.post('https://gsm2-api.aajkaa.in/applicant/getphotosfromimgtable', {
      CandidateID: props.data.CandidateID,
    }).then((response) => {
      setB(response.data[0].full_photo == null ? "" : response.data[0].full_photo)
      setA(response.data[0].profile_photo == null ? "" : response.data[0].profile_photo)
      setUploadedpp(response.data[0].profile_photo == null ? props.data.CandidateID + "pp00" : response.data[0].profile_photo.replace(/(\d)(?=\D*$)/, d => (parseInt(d) + 1) % 10))
      setUploadedfp(response.data[0].full_photo == null ? props.data.CandidateID + "fp00" : response.data[0].full_photo.replace(/(\d)(?=\D*$)/, d => (parseInt(d) + 1) % 10))
    });
  }, []);

  const [modalMessage, setModalMessage] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    history.push("/");
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
        <Modal.Header>
          <Modal.Title className="text-primary">GSM says</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-primary">{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose} autoFocus>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <form action="" onSubmit={formhandler} encType="multipart/form-data">
        {/* <form action="" encType="multipart/form-data"> */}
        <h2 className="text-primary text-center col-md-12">Upload Photos</h2>
        <p className="text-danger text-center text-bold mx-2 fs-3">Photos upload is compulsory. <br /> Your profile won't be published without photos.</p>
        <div className="row justify-content-center mx-2">
          <div className="col-md-5 mx-2">
            <label className="text-primary fs-5">Profile Photo</label>
            <input
              className="form-control"
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "inline-block" }}
              ref={inputRef1}
              required
            />{" "}
            <div className="d-flex gap-4 mt-4 justify-content-center">
              {a.length > 0 ?
                <img src={`https://gsm2-api.aajkaa.in/uploads/${a}`} width="200px" height="150px" alt="" className="img-fluid" />
                :
                <></>
              }
              {imagePreview && (
                <div>
                  <div className="text-center">
                    <img
                      className="my-3"
                      src={imagePreview}
                      alt="Thumb"
                      width="200px"
                      height="200px"
                    />
                  </div>

                  <button
                    type="button"
                    className="form-control btn btn-primary"
                    onClick={() => { handleRemoveImage(1) }}
                  >
                    Remove this Image
                  </button>
                </div>
              )}
            </div>
            <div>
            </div>
          </div>
          <div className="d-flex col-auto">
            <span className=" fs-2 border border-2 border-primary d-none d-md-inline"></span>
          </div>
          <div className="col-sm-5 mx-2">
            <label className="text-primary fs-5">Full Photo</label>
            <input
              className="form-control"
              type="file"
              accept="image/*"
              onChange={handleImageChange1}
              ref={inputRef2}
              required
            />
            <div className="d-flex gap-4 mt-4 justify-content-center">
              {a.length > 0 ?
                <img src={`https://gsm2-api.aajkaa.in/uploads/${b}`} width="200px" height="300px" alt="" className="img-fluid" />
                :
                <></>
              }
              {imagePreview1 && (
                <div>
                  <div className="text-center">
                    <img
                      className="my-3"
                      src={imagePreview1}
                      alt="Thumb"
                      width="200px"
                      height="200px"
                    />
                  </div>

                  <button
                    type="button"
                    className="form-control btn btn-primary"
                    onClick={() => { handleRemoveImage(2) }}
                  >
                    Remove this Image
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-12 justify-content-center text-center mt-4 mb-5">
          <button type="submit" className="btn btn-primary mx-1 fs-5 col-md-2">
            Upload & Save
          </button>
        </div>
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5 text-primary" id="exampleModalLabel">GSM Says</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <b className="text-primary">Your application has been saved. It will be visible to other candidates after the approval of the reference person.</b>
              </div>
              <div className="modal-footer">
                <Link to="/" className="btn btn-secondary" data-bs-dismiss="modal">Ok</Link>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default UploadPhoto;