import React, { useEffect, useState, useContext, useRef } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom';
import { ConfigTableContext } from '../../helpers/AuthContext'
import { Modal, Button } from "react-bootstrap";

const RequestApplicantId = () => {
  const history = useHistory();
  const { configTableContent } = useContext(ConfigTableContext);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA' || event.target.isContentEditable) {
        return;
      }
      switch (event.key) {
        case 'Backspace':
          event.preventDefault();
          history.push('/')
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const captchaVerified = localStorage.getItem('captcha');
    if (!captchaVerified) {
      history.push('/captcha')
    }
  }, []);

  const [show, setShow] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const handleClose = () => setShow(false);

  const [applicantNumber, setApplicantNumber] = useState("")
  const [generatedOtp, setGeneratedOtp] = useState("")
  const [otp, setOtp] = useState("")
  const [candidateId, setCandidateId] = useState("")
  const [candidateStatus, setCandidateStatus] = useState("")
  const [btnLoading, setBtnLoading] = useState(false);

  const okbtnRef = useRef(null);
  const whatsappnumberRef = useRef(null);
  const otpRef = useRef(null);

  const getApplicationStatus = () => {
    if (applicantNumber.length == 10) {
      axios.post('https://gsm2-api.aajkaa.in/applicant/resendapplicantid', {
        applicantNumber: applicantNumber,
      }).then((response) => {
        console.log(response.data)
        if (response.data == 'create your profile') {
          setShow(true);
          setModalMessage(["1", <><div>Create your profile.</div><br /><div>Use [Fill Application] button...</div><br /><Link to='/fillapplication' className="btn btn-primary">Fill Application</Link></>]);
          okbtnRef.current.focus();
        } else if (response.data == 'complete your profile') {
          setShow(true);
          setModalMessage(["1", <><div>Complete your profile.</div><br /><div>Use [Fill Application] button...</div><br /><Link to='/fillapplication' className="btn btn-primary">Fill Application</Link></>]);
          okbtnRef.current.focus();
        } else {
          setShow(true);
          setModalMessage(["2", <><div>OTP sent to your WhatsApp number.</div></>]);
          setGeneratedOtp(response.data.otp)
          setCandidateId(response.data.results1[0]["candidate_id"])
          setCandidateStatus(response.data.results1[0]["status"])
          otpRef.current.focus();
        }
      })
    } else {
      whatsappnumberRef.current.focus();
      setShow(true);
      setModalMessage(["2", "Enter Valid Number"]);
    }
  }

  const verifyOtpClicked = () => {
    if (otp == generatedOtp && otp.length == 4) {
      axios.post('https://gsm2-api.aajkaa.in/applicant/sendotpforresendapplicantid', {
        applicantNumber: applicantNumber,
        candidateId: candidateId,
        candidateStatus: candidateStatus
      }).then((response) => {
        setShow(true);
        setModalMessage(["1", <><div>{candidateStatus == "Approved" ? "Candidate" : "Applicant"}-ID sent to your verified Whatsapp number.</div></>]);
        okbtnRef.current.focus();
      })
    } else {
      setShow(true);
      setModalMessage(["2", <><div>OTP incorrect. Try again...</div></>]);
      otpRef.current.focus();
    }
  }

  return (
    <div>

      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
        <Modal.Header>
          <Modal.Title className="text-primary">GSM says</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-primary">
          {modalMessage[1]}
        </Modal.Body>
        <Modal.Footer>
          {
            modalMessage[0] == 2 ? (
              <Button variant="secondary" onClick={handleClose} ref={okbtnRef} autoFocus>
                Ok
              </Button>
            ) : (
              <Link to="/" className="btn btn-secondary" ref={okbtnRef}>Ok</Link>
            )
          }
        </Modal.Footer>
      </Modal>


      <h1 className="text-primary text-center mt-2">
        {configTableContent.project_header}
      </h1>
      <div className="container d-flex justify-content-between align-items-center mt-2">
        <Link to='/' className="btn btn-outline-primary">
          <i className="bi bi-caret-left-fill"></i> Back
        </Link>
        <h2 className="text-primary text-center flex-grow-1 m-0">Request Applicant-ID</h2>
        <div className="empty-space" style={{ width: '100px' }}></div>
      </div>

      <div className="container">
        <div className="h4 pb-2 mb-4 text-primary border-bottom border-primary border-3 text-center" id="title"></div>
        <div className="col-md-4">
          <label className="form-label fs-5 text-primary h1">Verified WhatsApp Number</label>
        </div>

        <div className="row g-3">

          <div className="col-md-3">
            <input
              className="form-control"
              id="input"
              type="number"
              placeholder="Enter WhatsApp number"
              onChange={(e) => { setApplicantNumber(e.target.value) }}
              autoComplete="off"
              autoFocus
              ref={whatsappnumberRef}
            />
          </div>
          <div className="col-md-9">
            {btnLoading ? (
              <button className="btn btn-primary" disabled>Sending OTP...</button>
            ) : (
              <button className="btn btn-primary" onClick={() => { getApplicationStatus(); }} >Get OTP</button>
            )}
          </div>
          <div className="col-md-3">
            <input
              className="form-control"
              type="number"
              placeholder="Enter OTP"
              onChange={(e) => { setOtp(e.target.value); }}
              ref={otpRef}
            />
          </div>
          <div className="col-md-3 mb-4">
            <button className="btn btn-primary" onClick={() => { verifyOtpClicked(); }}>
              Verify OTP And Send
            </button>
          </div>
        </div>

        {/* <div className="">
          <button type="button" className="btn btn-primary" onClick={() => { getApplicationStatus() }}>Send</button>
        </div> */}
      </div>
      <footer className="fixed-bottom" style={{ color: "white", backgroundColor: '#99999b', padding: '5px', textAlign: 'center', fontSize: "13px" }}>{configTableContent.project_footer}</footer>
    </div>
  )
}

export default RequestApplicantId
